import { fetchGet } from '../Service/ApiService'
import {authmodel} from '../Provider/AuthProvider'

export const loadDashboardData = (resolve, reject) => {      
    try {
        fetchGet('dashboard', null, {
            success: (res) => { resolve(res)},
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};