import React, { Component } from 'react';

import {loadDashboardData} from '../../Model/Dashboard'

const DashboardData = (ComponentToWrap) => {
    return class DashboardDataComponent extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            loading: true,
            statdata:{
              counts:null,
              list:{ customers:[],giftsend:[],giftpending:[],listings:[]}
            },
          };
          this.tm = null
          this.mount=false
        }

        componentDidMount(){
            this.mount=true
            this.tm = setInterval(()=>{ this.loadData() }, 50000)
            this.loadData()
        }

        componentWillUnmount(){
            this.mount=false
            if(this.tm){
                clearInterval(this.tm)
            }
        }
        

        loadData() {
          loadDashboardData((res) => {
            if(this.mount)this.setState({ statdata:res.data, loading:false})
          }, (res) => {
          });
        }

        render() { 
            const {statdata, loading} = this.state            
            return (<ComponentToWrap {...this.props} statdata={statdata} dataLoading={loading} />)
        }
    }

}

export default DashboardData