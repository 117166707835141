
export const UserUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if (data.id) {
        formdata.append('id', data.id)
    }
    if (data.name) {
        formdata.append('name', data.name)
    }
    if (data.action) {
        formdata.append('action', data.action)
    }
    if (data.rating_point) {
        formdata.append('rating_point', data.rating_point)
    }
    if (data.email) {
        formdata.append('email', data.email)
    }
    if (data.phone) {
        formdata.append('phone', data.phone)
    }
    if (data.status) {
        formdata.append('status', data.status)
    }
    if (data.country) {
        formdata.append('country', data.country)
    }
    if (data.states) {
        formdata.append('states', data.states)
    }
    if (data.cities) {
        formdata.append('cities', data.cities)
    }
    if (data.reason) {
        formdata.append('reason', data.reason)
    }
    if(data.current_plan){
        formdata.append('current_plan',data.current_plan)
    }
    if(data.payment_proof){
        formdata.append('payment_proof',data.payment_proof.rawFile)
    }
    if(data.basic_user_count){
        formdata.append('basic_user_count',data.basic_user_count)
    }
    if(data.premium_user_count){
        formdata.append('premium_user_count',data.premium_user_count)
    }
    if(data.utr){
        formdata.append('utr',data.utr)
    }
    if (data.pword) {
        formdata.append('pword', data.pword)
    }
    if (data.cpassword) {
        formdata.append('cpassword', data.cpassword)
    }
    return formdata;
}