import React from 'react';
import { Create, SimpleForm, TextInput, useRedirect, useNotify, Toolbar, ListButton, SaveButton, ReferenceArrayInput, AutocompleteArrayInput, SelectArrayInput, ImageInput, ImageField, required } from 'react-admin';

import { ShopStatusSelectInput } from '../../UI/Input/SelectInput'
import { onShopCreate } from '../../Service/Validation/ShopValidation'
import Grid from '@material-ui/core/Grid';

const PageTitle = () => {
    return <span>Create Admin User</span>;
};

const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Save" redirect="show" submitOnEnter={true} />
        {!props.saving && <ListButton label="Cancel" />}
    </Toolbar>
)
const SanitizedGrid = ({ basePath, ...props }) => {
    return (
        <Grid {...props} />
    );
};

const ShopCreate = (props) => {
    const notify = useNotify();

    const onError = (error) => {
        var errorArr = [];
        Object.keys(error.body.errors).forEach(function (key) {
            errorArr.push(error.body.errors[key])
        });
        notify(`Could not create item: ${errorArr.toString()}`, { type: 'error' });
    };

    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Shop created successfully`); // default message is 'ra.notification.updated'
        redirect('list', 'shop');
    };
    return (
        <Create title={<PageTitle />} {...props} undoable={false} mutationOptions={{ onError,onSuccess }}>
            <SimpleForm toolbar={<FormToolbar />} warnWhenUnsavedChanges validate={onShopCreate}>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Email" source="email" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Phone" source="phone" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Password" source="password" type="password" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Confirm Password" source="confirmpassword" type="password" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <ShopStatusSelectInput label="Status" source="status" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="City" source="city" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="State" source="state" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Country" source="country" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="GST Number" source="gst_number" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Address" source="address" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Owner name" source="owner_name" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Owner phone" source="owner_phone" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Owner email" source="owner_email" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Owner address" source="owner_address" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Customer discount" source="customer_discount" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="SBO margin" source="sbo_margin" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Description" source="description" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Latitude" source="latitude" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Longitude" source="longitude" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <ReferenceArrayInput source="category_id" perPage={5} reference="shopcategory" fullWidth >
                            <AutocompleteArrayInput optionText={(record) => { return record && record.name ? record.name : '' }} />
                            {/* <AutocompleteArrayInput fullWidth  label="category"/> */}
                        </ReferenceArrayInput>
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <ImageInput source="shop_profile_photo" label="shop profile photo" accept="image/*" maxSize={2000000}>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </Grid>
                </SanitizedGrid>
            </SimpleForm>
        </Create>
    );
}
export default ShopCreate