import * as React from "react";
import { Show, SimpleShowLayout,ShowController, Pagination, TabbedShowLayout, Datagrid, List, RichTextField, Tab, useRecordContext, Button, TextField, FunctionField, ChipField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { Link } from 'react-router-dom';

const ProfileidFiled = (props) => {
  const record = useRecordContext();
  return (
    <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
  )
}


const PublicCloudStorageDetail = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Show title="File Detail" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />

        <ProfileidFiled source="profile_id" label="Profile Id" />
        <TextField source="name" label="Name" />
        <TextField source="category" label="Category" />
        <TextField source="description" label="Description" />
        <ChipField source="file_size" label="File Size" />
        <FunctionField source="status" label="Status" render={record => {
          if (record['status'] === 'Decline') {
            return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
          } else if (record['status'] === 'Pending') {
            return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
          } else if (record['status'] === 'Active') {
            return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
          } else if (record['status'] === 'Suspended') {
            return (<Button style={{ color: 'blue', border: '1px solid' }} label="Suspended" size="small"></Button>)
          }
        }} />
        <TextField source="reason" label="Reason" />
        <FunctionField source="declineby.name" label="Decline By" render={record => {
          return (<TextField source="declineby.name" label="Decline By" />)
        }} />

        <FunctionField source="approvedby.name" label="Approved By" render={record => {
          return (<TextField source="approvedby.name" label="Approved By" />)
        }} />

        <TextField source="approved_date" label="Approved Date" />

        <TextField source="created_at" label="Created Date" />
        <TextField source="updated_at" label="Updated Date" />
        {/* <FunctionField source="status" label="File" render={record => {
            if (record['file_type'] === 'Image') {
              return (<InsertPhotoOutlinedIcon />
              // <span>Payment Proof <br /><br />
              //   <img style={{ maxWidth: '150px' }} src={record['fileurl']} alt={record.name} /></span>
                )
            } else if (record['file_type'] === 'Document') {
              return (<ArticleOutlinedIcon />)
            } else if (record['file_type'] === 'Video') {
              return ( <MissedVideoCallOutlinedIcon />)

            } else {
              return ( <RedeemOutlinedIcon />)
            }
          }} /> */}
      </SimpleShowLayout>
    </Show>

  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default PublicCloudStorageDetail