import * as React from "react";
import { Show, SimpleShowLayout,useRecordContext,Button, TextField,FunctionField } from 'react-admin';
const PageTitle = () => {
    const record = useRecordContext();
    return <span>User {record ? `${record.name}` : ''}</span>;
  };
const UserCommandDetail = ({...props }) => (

    <Show title={<PageTitle />} {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="profile_id"  label="Profile ID"/>       
        <TextField source="article_title" label="Article Title" />
        <TextField source="email" label="Email" />    
        <TextField source="name"  label="Name"/>       
        <TextField source="rating" label="Rating" />  
        <TextField source="comments" label="Comments" />
        <TextField source="created_at" label="Created At" />
        <TextField source="updated_at" label="Updated At" />
        </SimpleShowLayout>
    </Show>
     );
export default UserCommandDetail