import * as React from "react";
import { Show, SimpleShowLayout,useRecordContext,Button, TextField,FunctionField } from 'react-admin';
const PageTitle = () => {
    const record = useRecordContext();
    return <span>Withdraw request for {record ? `${record.profile_id}` : ''}</span>;
  };
const WelcomeBonusWithdrawRequestDetail = ({...props }) => (

    <Show title={<PageTitle />} {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="profile_id" label="Profile Id" />
        <TextField source="user.name" label="Name" />
        <TextField source="req_cvamount" label="CV amount" />  
        <TextField source="total_amount" label="Transfer Amount" />        
        <TextField source="req_type" label="Request Type" />   
        <TextField label="Request Datetime" source="req_datetime"/> 
        <TextField label="Approved by" source="approvedby.name"/> 
        <TextField label="Decline by" source="declineby.name"/>
        <TextField label="Decline Reason" source="decline_reason"/>
        <FunctionField source="req_status" label="Status" render={record => {  
          if (record['req_status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['req_status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['req_status'] === 'Sent'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Sent" size="small"></Button>)
          }else if(record['req_status'] === 'Cancelled'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} /> 
        </SimpleShowLayout>
    </Show>
     );
export default WelcomeBonusWithdrawRequestDetail