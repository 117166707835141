import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { StatusSelectInput } from '../../UI/Input/SelectInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <StatusSelectInput label="Status" source="status" />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.created_by}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.updated_by}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const ContentWriterKeywordList = ({ ...props }) => {

    return (
        <List {...props} title="Content Writer Keywords" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'keyword', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <TextField source="keyword" label="Keyword" />
                <AdminUserIDFiled source="createdby.name" label="Created by" />
                <AdminUserIDUpdateFiled source="updatedby.name" label="Updated by" />
                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Deactive') {
                        return (<span style={{ color: 'red' }}>Deactive</span>)
                    } else {
                        return (<span style={{ color: 'green' }}>Active</span>)
                    }
                }} />
                <EditButton />
                <DeleteButton />
                <ShowButton />
            </Datagrid>

        </List>
    )
}


export default ContentWriterKeywordList