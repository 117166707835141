import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  EditButton,Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  ShowButton,
  TopToolbar ,
  DateInput,
  useListContext,
  FunctionField


} from "react-admin";
import { makeStyles } from '@material-ui/core';
import BulkRejectButton from '../SboIncometaxApplicant/Action/Bulk.Reject.Button';
import BulkActiveButton from '../SboIncometaxApplicant/Action/Bulk.Active.Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import {UserStatusSelectInput,IncomePlanSelectInput} from '../../UI/Input/SelectInput'
import { stringify } from 'query-string';
const ListFilter = (props) => {
  const {
    
    setFilters,
    hideFilter
} = useListContext();
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />  
    <DateInput source="current_date" lable="Date" alwaysOn/>
    <IncomePlanSelectInput label="Plan Type" source="plan_type" alwaysOn/>
    
    <UserStatusSelectInput label="Status" source="status" alwaysOn/>
  </Filter>)
};



const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}



const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
       
        <BulkRejectButton label="Bulck Reject"  {...props} />
        <BulkActiveButton label="Bulck Approve"  {...props} />
    </Fragment>)
}

const ListActions  = (props) => {
  const record = useListContext();
 const filva = record.filterValues ? stringify(record.filterValues) : ''; 
 return ( <TopToolbar>
     <Button  href={`//sboportal.org.in/admin/api/v1/sboincometaxapplicants/export?${filva}`} label="Export Data" ><DownloadIcon /></Button>
  </TopToolbar>);
}

const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const SboIncometaxApplicantList = ({ ...props }) => {
  const classes = useStyles()
  const [userstatusCreate, setUserStatusCreate] = React.useState(null)
  const handleStatusSelect = (record) => {
      setUserStatusCreate({ record: record })
  }

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="Sbo Income Tax Applicants"
      
     
       bulkActionButtons={<BulkActionButtons />}
       actions={<ListActions/>}
       filters={<ListFilter />}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/> 
        <TextField source="name" label="Name" />   
        <TextField source="dob" label="dob" />   
        <TextField source="plan_type" label="Plan" />    
        <TextField source="email" label="Email" />   
        <TextField source="aadhar_registered_mobile" label="Aadhar Registered Mobile" />
        <TextField source="contact_number" label="Phone" />  
        <TextField source="pan_number" label="Pan Number" />   
        <TextField source="aadhar_number" label="Aadhar Number" />    
        <TextField source="income_tax_password" label="Incometax Password" />   
        <TextField source="remarks" label="Remarks" />   
        <TextField source="trade_business_name" label="Trade Business Name" />   
        
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }else if(record['status'] === 'Suspended'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Suspended" size="small"></Button>)
          }                         
          }} /> 

           <FunctionField source="user_photo" label="Photo Copy" render={record => {  
            console.log(record)
            console.log('record34')
              if (record['photo_copyurl']) {
                return ( <span>Photo Copy <br/><br/><img style={{maxWidth:'100px'}} src={record['photo_copyurl']} alt={record.name}/></span>)
              }                       
            }} />   

             <FunctionField source="aadhar_picture" label="Aadhar Proof" render={record => {  
              if (record['aadhar_pictureurl']) {
                return ( <span>Aadhar Proof <br/><br/><img style={{maxWidth:'100px'}} src={record['aadhar_pictureurl']} alt={record.name}/></span>)
              }                       
            }} />   

             <FunctionField source="pan_picture" label="Pan Proof" render={record => {  
              if (record['pan_pictureurl']) {
                return ( <span>Pan Proof <br/><br/><img style={{maxWidth:'100px'}} src={record['pan_pictureurl']} alt={record.name}/></span>)
              }                       
            }} />   
            <TextField source="created_at" label="Created At" /> 
      </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default SboIncometaxApplicantList;
