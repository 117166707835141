import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  NumberInput,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Status2SelectInput, BasicPlanSelectInput } from "../../UI/Input/SelectInput";
import { onUpgradePlanEdit } from "../../Service/Validation/UpgradePlanValidation";
const PageTitle = ({ record }) => {
  return <span>Upgrade Plan: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const UpgradePlanEdit = (props) => {
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onUpgradePlanEdit} redirect="list">
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Upgrade Level" source="plan_name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Video Amount" source="video_amount" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Video Count" source="video_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <BasicPlanSelectInput label="Plan" source="basic_plan_id" alwaysOn allowEmpty={true} emptyText="All" />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Status2SelectInput label="Status" source="status" fullWidth />
          </Grid>

          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Eligible Member Count" source="eligible_member_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Public Video Count" source="public_video_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Public Video Amount" source="public_video_amount" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default UpgradePlanEdit;
