import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  EditButton,Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  ShowButton,
  TopToolbar ,
  DateInput,
  useListContext


} from "react-admin";
import { makeStyles } from '@material-ui/core';
import BulkRejectButton from '../UserCommands/Action/Bulk.Reject.Button';
import DownloadIcon from '@material-ui/icons/GetApp';

const ListFilter = (props) => {
  const {
    
    setFilters,
    hideFilter
} = useListContext();
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />  
    <DateInput source="current_date" lable="Date" alwaysOn/>
 
  </Filter>)
};



const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}



const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
       
        <BulkRejectButton label="Bulck Delete"  {...props} />
    </Fragment>)
}

const ListActions = () => (
  <TopToolbar>
     <Button  href={'//sboportal.org.in/admin/api/v1/usercommand/export?'} label="Export" ><DownloadIcon /></Button>
  </TopToolbar>
);

const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const UserCommandList = ({ ...props }) => {
  const classes = useStyles()
  const [userstatusCreate, setUserStatusCreate] = React.useState(null)
  const handleStatusSelect = (record) => {
      setUserStatusCreate({ record: record })
  }

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="User Commands"
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
       bulkActionButtons={<BulkActionButtons />}
       actions={<ListActions/>}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/> 
        <TextField source="name" label="Name" />      
        <TextField source="article_title" label="Article Title" />
        <TextField source="email" label="Email" />    
        <TextField source="rating" label="Rating" />  
        <TextField source="comments" label="Comments" />
          <EditButton/>
          <ShowButton/>
        
      </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default UserCommandList;
