import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const ShopCategoryDetail = ({...props }) => (

    <Show title="Category Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" label="Category Name" />
            <TextField source="status" label="Status" />
            <TextField source="icon" label="Icon" />
            <TextField source="descripton" label="Description" />
        </SimpleShowLayout>
    </Show>
     );
export default ShopCategoryDetail