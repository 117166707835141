import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const LanguageVideoDetail = ({...props }) => (

    <Show title="Language video Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" label="Name" />
            <TextField source="description" label="Description" />
            <TextField source="urltype" label="Url Type" />
            <TextField source="video_url" label="video Url" />
            <TextField source="youtube_url" label="Youtube URL" />
        </SimpleShowLayout>
    </Show>
     );
export default LanguageVideoDetail