import * as React from "react";
import { Show, SimpleShowLayout, TextField,FunctionField,useRecordContext, Link } from 'react-admin';
const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const ContentWriterKeywordDetail = ({ ...props }) => (

    <Show title="Content writer keyword detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="keyword" label="Keyword" />
            <AdminUserIDFiled source="createdby.name" label="Created by" />
            <AdminUserIDUpdateFiled source="updatedby.name" label="Updated by" />
            <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Deactive') {
                    return (<span style={{ color: 'red' }}>Deactive</span>)
                } else {
                    return (<span style={{ color: 'green' }}>Active</span>)
                }
            }} />
        </SimpleShowLayout>
    </Show>
);
export default ContentWriterKeywordDetail