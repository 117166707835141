import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { onBasicSettingsCreate } from "../../Service/Validation/BasicSettingsValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Basic Settings</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const BasicSettingsCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onBasicSettingsCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={6} lg={6}>
        <NumberInput label="Total User Count" source="total_user_count_admin" fullWidth/>
        </Grid>
       
        {/* <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Video Wallet Min Amount" source="videowallet_min_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Admin Charge" source="admincharge" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Basic User Refer Amont" source="basic_user_refer_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Primium User Refer Amont" source="primium_user_refer_amont" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Email" source="email" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Companey Name" source="companey_name" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Phone" source="phone" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Address" source="address" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Reward point per rupee" source="points_per_rupee" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Reward percentage to sponsor" source="reward_percentage" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="User Wallet Min Amount" source="userwallet_min_amount" fullWidth/>
        </Grid> */}
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default BasicSettingsCreate;
