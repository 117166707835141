
export const BulckBonusUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if(data.attachments){
        formdata.append('attachments',data.attachments.rawFile)
    }

    return formdata;
}