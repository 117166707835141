import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  useNotify,
  useRedirect,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { ShopCategoryStatusSelectInput } from "../../UI/Input/SelectInput";
import { onShopKeywordCreate } from "../../Service/Validation/ShopKeywordValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Shop Keyword</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const ShopKeywordCreate = (props) => {
  const notify = useNotify();

  const onError = (error) => {
    var errorArr = [];
    Object.keys(error.body.errors).forEach(function (key) {
      errorArr.push(error.body.errors[key])
    });
    notify(`Could not create item: ${errorArr.toString()}`, { type: 'error' });
  };

  const redirect = useRedirect();
  const onSuccess = () => {
    notify(`Shop keyword created successfully`); // default message is 'ra.notification.updated'
    redirect('list', 'shopkeyword');
  };
  const redirects = (basePath, id, data) => `/shopkeyword/show`;
  return (
    <Create title={<PageTitle />} {...props} undoable={false}  mutationOptions={{ onError, onSuccess }}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" redirect="list" warnWhenUnsavedChanges validate={onShopKeywordCreate}>

        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Keyword" source="keyword" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Icon" source="icon" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <ShopCategoryStatusSelectInput label="Status" source="status" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <TextInput label="Description" source="description" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};
export default ShopKeywordCreate;
