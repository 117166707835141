import * as React from "react";
import { Show, SimpleShowLayout, TextField,RichTextField,ImageField  } from 'react-admin';
const ArticleDetail = ({...props }) => (

    <Show title="Basic Plan Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="page_title" label="Page Title" />
            <ImageField source="imageurl" label="Image" />
            <TextField source="bvideo" label="Before Description Video" />
            <RichTextField  source="description" label="Description" />
            <TextField source="websitelink" label="Website Link" />
            <TextField source="avideo" label="After Description Video" />
            <TextField source="status" label="Status" />
        </SimpleShowLayout>
    </Show>
     );
export default ArticleDetail