import { LanguageVideoUpload } from "./LanguageVideo.Upload"
import { UserUpload } from "./User.Upload"
import { BulckBonusUpload } from "./BulckBonus.Upload"
import {BulckUserActiveUpload}from "./BulckUserActive.Upload" 
import {AddBulckUserWelcomeBonusUpload}from "./AddBulckUserWelcomeBonus.Upload" 
import{ArticleUpload}from "./Article.Upload" 
import{ShopUpload}from "./Shop.Upload" 
import{BulckUserActiveWithIntroUpload}from "./BulckUserActiveWithIntro.Upload" 

const Uploader = (resource, formdata) => {
    switch (resource) {       
        case 'languagevideo': return LanguageVideoUpload(formdata);   
        case 'shop': return ShopUpload(formdata);   
        case 'user': return UserUpload(formdata);    
        case 'adminbulckbonusadd': return BulckBonusUpload(formdata); 
        case 'adminbulckuseractive': return BulckUserActiveUpload(formdata); 
        case 'adminbulckuseraddwelcomebonus': return AddBulckUserWelcomeBonusUpload(formdata); 
        case 'adminbulckuseraddwelcomebonus': return AddBulckUserWelcomeBonusUpload(formdata); 
        case 'article':return ArticleUpload(formdata); 
        case 'adminbulckuseractivewithintro': return BulckUserActiveWithIntroUpload(formdata); 
        default: return formdata;
    }
}

export default Uploader