import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const VideoListDetail = ({...props }) => (

    <Show title="Video List Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
           
            <TextField source="video_type" label="Video Type" />
            <TextField source="video_url" label="video Url" />
            <TextField source="youtube_id" label="Youtube URL" />
            <TextField source="video_name" label="Video Name" />
            <TextField source="status" label="Status" />
        </SimpleShowLayout>
    </Show>
     );
export default VideoListDetail