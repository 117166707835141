import React from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  FileInput,
  TextInput,
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";
import { onLanguageVideoEdit } from "../../Service/Validation/LanguageVideoValidation";
const PageTitle = ({ record }) => {
  return <span>Language Video: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const LanguageVideoEdit = (props) => {  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
            <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar/>} defaultValues={{ action: "full" }} validate={onLanguageVideoEdit} redirect="list">                
                <TextInput label="Name" source="name" /> 
                <TextInput label="Description" source="description" fullWidth multiline/>
                <VideoTypeSelectInput label="Type" source="urltype" fullWidth />
                <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.urltype === "Video" && (
                  <FileInput source="video_url"  maxSize={5000000} accept="video/*" placeholder={<p>Drop a file to upload, or click to select it. and upload only 5 mb</p>}>
                    <FileField source="src" title="title" />
                  </FileInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.urltype === "Youtube" && (
                  <TextInput label="Youtube" source="youtube_url" fullWidth />
                )
              }
            </FormDataConsumer>
            </SimpleForm>
        </Edit>
  );
};

export default LanguageVideoEdit;
