import { fetchPost, AUTH_USER_KEY } from '../Service/ApiService'


export const onSignIn = (val) => {
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(val))
}

export const onSignOut = () => {
    localStorage.removeItem(AUTH_USER_KEY)
}

export const getAuthUser = () => {
    return JSON.parse(localStorage.getItem(AUTH_USER_KEY))
}

export const loginUser = (credentials, resolve, reject) => {
    try {
        //Check token against existing user
        fetchPost('account/login', credentials, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};
