import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  FileInput,
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onCategoryListCreate } from "../../Service/Validation/CategoryListValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Category</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const CategoryListCreate = (props) => {
  console.log(props);
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onCategoryListCreate} redirect="list">

        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Category Name" source="name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Amount" source="amount" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Status2SelectInput label="Status" source="status" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <TextInput label="Description" source="description" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};
export default CategoryListCreate;
