import React, { Fragment } from 'react'
import { List, Link,useRecordContext, Datagrid,TopToolbar,ChipField, Filter,Button, ShowButton, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton } from 'react-admin'
import { UserStatusSelectInput } from '../../UI/Input/SelectInput'
import DownloadIcon from '@material-ui/icons/GetApp';
const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <UserStatusSelectInput label="Status" source="status" alwaysOn/>
    </Filter>
)
const ListActions = () => (
    <TopToolbar>
       <Button  href={'//sboportal.org.in/admin/api/v1/deepavalibonus/export?'} label="Dewali Export" ><DownloadIcon /></Button>
    </TopToolbar>
  );
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const ProfileidFiled = (props)=>{
    const record = useRecordContext();  
    return (
    <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
  )}
const DeepavaliBonusList = ({ ...props }) => {

    return (
        <List {...props}  actions={<ListActions/>} title="Deepavali Bonus" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'name', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <TextField source="name" label="Name" />
                <ProfileidFiled source="profile_id" label="Profile Id" />
                <ChipField source="basic_downliner_count" label="Basic Count"/> 
                <ChipField source="premium_downliner_count" label="Premium Count"/> 
                <TextField source="remarks" label="Remarks" />
                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Decline') {
                        return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
                    } else if (record['status'] === 'Pending') {
                        return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
                    } else if (record['status'] === 'Active') {
                        return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
                    } else if (record['status'] === 'Suspended') {
                        return (<Button style={{ color: 'blue', border: '1px solid' }} label="Suspended" size="small"></Button>)
                    }
                }} />

            </Datagrid>

        </List>
    )
}


export default DeepavaliBonusList