

export const onCodeCreate = (values) => {

    const errors = {}
     if (required(values.code)) {
        errors.code = 'Code is required'
    } 
   
    if (required(values.status )) {
        errors.status  = 'Status required'
    }
    

    return errors;
   
};

export const onCodeEdit = (values) => {

    const errors = {}
    if (required(values.code)) {
        errors.code = 'Code is required'
    } 
  

    if (required(values.status )) {
        errors.status  = 'Status required'
    }
    return errors;
   
};

















const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}


