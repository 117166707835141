import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const CodeDetail = ({...props }) => (

    <Show title="Code Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
           
            <TextField source="code" label="Code" />
          
            <TextField source="status" label="Status" />
        </SimpleShowLayout>
    </Show>
     );
export default CodeDetail