import { Menu } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label';

export const MyMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.ResourceItem name="adminuser" />
        <Menu.ResourceItem name="shop" />
        <Menu.ResourceItem name="shopcategory" />
        <Menu.ResourceItem name="digitaltaskapplication" />
        <Menu.ResourceItem name="influencer" />
        <Menu.ResourceItem name="influencerall" primaryText="AllTask" leftIcon={<LabelIcon />} />
        <Menu.ResourceItem name="influencerrating" />
        <Menu.ResourceItem name="shopbank" />
        <Menu.ResourceItem name="pendinguser" />
        <Menu.ResourceItem name="usercommands" />
        <Menu.ResourceItem name="user" />
        <Menu.ResourceItem name="contentwriterkeywords" />
        <Menu.ResourceItem name="contentwriterkeywordamount" />
        <Menu.ResourceItem name="contentwriter" />
        <Menu.ResourceItem name="merchandiserapply" />
        <Menu.ResourceItem name="deepavalibonus" />
        <Menu.Item to="/adminbulckuseractivewithintro/create" primaryText="New Joiner Bulck Active" leftIcon={<LabelIcon />}/>
        <Menu.ResourceItem name="withdrawrequest" />
        <Menu.ResourceItem name="planupgraderequest" />
        <Menu.ResourceItem name="languagevideo" />
        <Menu.ResourceItem name="videolist" />
        <Menu.ResourceItem name="categorylist" />
        <Menu.ResourceItem name="code" />
        <Menu.ResourceItem name="userutr" />
        <Menu.ResourceItem name="basicplan" />
        <Menu.ResourceItem name="upgradeplan" />
        <Menu.ResourceItem name="introwithdrawrequest" />
        <Menu.ResourceItem name="buybackwithdrawrequest" />
        <Menu.ResourceItem name="welcomebonuswithdrawrequest" />
        <Menu.ResourceItem name="basicsettings" />
        <Menu.ResourceItem name="article" />
        <Menu.ResourceItem name="sboincometaxapplicant" />
        <Menu.ResourceItem name="onlinecourse" />
        
        <Menu.Item to="/adminbonusadd/create" primaryText="Add Bonus" leftIcon={<LabelIcon />}/>
        <Menu.Item to="/adminbulckuseraddwelcomebonus/create" primaryText="Add Bulck User Welcome Bonus" leftIcon={<LabelIcon />}/>
        <Menu.Item to="/adminbulckuseractive/create" primaryText="Bulck User Active" leftIcon={<LabelIcon />}/>
       
        
        <Menu.Item to="/adminbulckbonusadd/create" primaryText="Add Bulk Bonus" leftIcon={<LabelIcon />}/>

        <Menu.Item to="/adminsingleuseraddordeduct/create" primaryText="Single User Add or Deduct" leftIcon={<LabelIcon />}/>

        <Menu.Item to="/adminbulckuseraddworkingamount/create" primaryText="Bulck Add Working Non-working" leftIcon={<LabelIcon />}/>

        <Menu.ResourceItem name="publiccloudstoragelist" />
      
    </Menu>
);