import React from 'react';
import { Create, SimpleForm, TextInput, useNotify, Toolbar, ListButton, SaveButton, ReferenceInput, SelectInput } from 'react-admin';

import { StatusSelectInput, ShopSelectInput } from '../../UI/Input/SelectInput'
import { onShopBankCreate } from '../../Service/Validation/ShopBankValidation'
import Grid from '@material-ui/core/Grid';

const PageTitle = () => {
    return <span>Create Shop Bank</span>;
};

const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Save" redirect="show" submitOnEnter={true} />
        {!props.saving && <ListButton label="Cancel" />}
    </Toolbar>
)
const SanitizedGrid = ({ basePath, ...props }) => {
    return (
        <Grid {...props} />
    );
};

const ShopBankCreate = (props) => {
    const notify = useNotify();

    const onError = (error) => {
        var errorArr = [];
        Object.keys(error.body.errors).forEach(function (key) {
            errorArr.push(error.body.errors[key])
        });
        notify(`Could not create item: ${errorArr.toString()}`, { type: 'error' });
    };
    return (
        <Create title={<PageTitle />} {...props} undoable={false} mutationOptions={{ onError }}>
            <SimpleForm toolbar={<FormToolbar />} warnWhenUnsavedChanges validate={onShopBankCreate}>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <ShopSelectInput label="Shop" source="shop_id" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Account Number" source="account_no" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="IFSC" source="ifsc" fullWidth />
                    </Grid>
                </SanitizedGrid>
                <SanitizedGrid container spacing={3} fullWidth>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Account holder name" source="account_holder_name" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Branch" source="branch" fullWidth />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <TextInput label="Bank Name" source="bank_name" fullWidth />
                    </Grid>
                </SanitizedGrid>
            </SimpleForm>
        </Create>
    );
}
export default ShopBankCreate