import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  Toolbar,
  ListButton,
  SaveButton,
  ImageInput,
} from "react-admin";
import { onArticleCreate } from "../../Service/Validation/ArticleValidation";
import Grid from "@material-ui/core/Grid";
import { RichTextInput } from 'ra-input-rich-text';
import { Status2SelectInput } from "../../UI/Input/SelectInput";
const PageTitle = () => {
  return <span>Create Article</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const ArticleCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onArticleCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Page Title" source="page_title" fullWidth/>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Before Description Video" source="bvideo" fullWidth/>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
        <ImageInput source="image" label="Image">
          <ImageField source="src" title="title" />
    </ImageInput>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Website Link" source="websitelink" fullWidth/>
        </Grid>
       
        <Grid item sm={12} md={12} lg={12}>
        <RichTextInput  label="Description" source="description" fullWidth/>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="After Description Video" source="avideo" fullWidth/>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
<Status2SelectInput label="Status" source="status" fullWidth/>
</Grid>
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default ArticleCreate;
