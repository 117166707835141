import { createMuiTheme } from '@material-ui/core/styles';

export const WtTheme = createMuiTheme({
    overrides: {

        MuiButton: {
            root: { marginLeft: 5 },            
            text: { color: 'white', },
        },

        MuiTableCell:{
            head:{
                //backgroundColor:'rgba(256,256,256,0) !important',
                borderBottom: '2px solid #ccc',
                fontWeight:"bold"
            }
        },

        RaMenuItemLink:{
            active: {
                backgroundColor : '#fafafa',
            },
        },
    },

    props:{
        MuiButton:{
            variant:"outlined",
            color:"primary"
        }
    }
});