import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const BasicPlanDetail = ({ ...props }) => (

    <Show title="Basic Plan Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="plan_name" label="Plan Name" />
            <TextField source="plan_amount" label="Plan Amount" />
            <TextField source="video_amount" label="Video Amount" />
            <TextField source="video_count" label="Video Count" />
            <TextField source="status" label="Status" />
            <TextField source="public_video_count" label="Public Video Count" />
            <TextField source="public_video_amount" label="Public Video Amount" />
        </SimpleShowLayout>
    </Show>
);
export default BasicPlanDetail