import React from "react";
import {Edit,FormDataConsumer,useRecordContext,FunctionField,required,SimpleForm,ImageInput,ImageField,TextInput, Toolbar, ListButton,SaveButton, NumberInput} from "react-admin";

import { StatusSelectInput } from "../../UI/Input/SelectInput";
import { onUserCommandsEdit } from "../../Service/Validation/UserValidation";
import Grid from "@material-ui/core/Grid";
import {UserPendingStatusSelectInput,BasicPlanSelectInput,UserActiveStatusSelectInput,UserSuspendedStatusSelectInput,UserDeclineStatusSelectInput} from '../../UI/Input/SelectInput'
// const PageTitle = ({ record }) => {
//   return <span>User: {record ? record.name : ""}</span>;
// };
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const UserCommandEdit = (props) => {
  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} validate={onUserCommandsEdit} redirect="list" defaultValues={{ action: "full" }}>       
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Profile ID" source="profile_id" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Email" source="email" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
            <TextInput disabled label="Article Title" source="article_title" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={4}>
                <NumberInput label="Rating" source="rating" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
                <TextInput label="Comments" source="comments" fullWidth />
            </Grid>

            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Name" source="name" fullWidth />
            </Grid>
         
         
          </SanitizedGrid>
         
           

      </SimpleForm>
    </Edit>
  );
};

export default UserCommandEdit;
