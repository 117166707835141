export const onShopKeywordEdit = (values) => {

    const errors = {}

    if (required(values.status)) {
        errors.status = 'Status required'
    }    
    if (required(values.keyword)) {
        errors.keyword = ['Keyword required']
    }
    return errors;

};

export const onShopKeywordCreate = (values) => {

    const errors = {}
    if (required(values.status)) {
        errors.status = 'Status required'
    }    
    if (required(values.keyword)) {
        errors.keyword = ['Keyword required']
    }
    return errors;
    

};
const isEmpty = (value: any) => {
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) => {
    return isEmpty(value)
}