
export const ArticleUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if (data.id) {
        formdata.append('id', data.id)
    }
    if (data.page_title) {
        formdata.append('page_title', data.page_title)
    }
    if (data.action) {
        formdata.append('action', data.action)
    }
    if (data.description) {
        formdata.append('description', data.description)
    }
    if (data.bvideo) {
        formdata.append('bvideo', data.bvideo)
    }
    if (data.avideo) {
        formdata.append('avideo', data.avideo)
    }
    if(data.status){
        formdata.append('status',data.status)
    } if(data.image){
        formdata.append('image',data.image.rawFile)
    }
    if(data.websitelink){
        formdata.append('websitelink',data.websitelink)
    }

    return formdata;
}