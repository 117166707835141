
export const LanguageVideoUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if (data.id) {
        formdata.append('id', data.id)
    }
    if (data.name) {
        formdata.append('name', data.name)
    }
    if (data.action) {
        formdata.append('action', data.action)
    }
    if (data.description) {
        formdata.append('description', data.description)
    }
    if (data.urltype) {
        formdata.append('urltype', data.urltype)
    }
    if (data.youtube_url) {
        formdata.append('youtube_url', data.youtube_url)
    }
    if(data.urltype==="Video"){
        formdata.append('video_url',data.video_url.rawFile)
    }else{
        if (data.video_url) {
            formdata.append('video_url', data.video_url)
        } 
    }

    return formdata;
}