import { fetchPost } from '../Service/ApiService'
import { authmodel } from '../Provider/AuthProvider';



export const singleApprovePublicCloudStorage = (id, resolve, reject) => {


    try {
        fetchPost(`pubiccloudstorage/singleapprove`, id, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const singleRejectPublicCloudStorage = (id, resolve, reject) => {


    try {
        fetchPost(`pubiccloudstorage/singlereject`, id, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const approvePublicCloudStorage = (selectedIds, resolve, reject) => {


    try {
        fetchPost(`pubiccloudstorage/bulckapprove`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};


export const bulckRejectPublicCloudStorage = (selectedIds, resolve, reject) => {


    try {
        fetchPost(`pubiccloudstorage/bulckreject`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};


export const deletePublicCloudStorage = (selectedIds, resolve, reject) => {


    try {
        fetchPost(`pubiccloudstorage/bulckdelete`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};






