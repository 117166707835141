import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  FileInput, 
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";
import { onLanguageVideoCreate } from "../../Service/Validation/LanguageVideoValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Video</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const LanguageVIdeoCreate = (props) => {
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onLanguageVideoCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Name" source="name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput
              label="Description"
              source="description"
              fullWidth
              multiline
            />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <VideoTypeSelectInput label="Type" source="urltype" fullWidth />
          </Grid>
        </SanitizedGrid>
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={12} lg={12}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.urltype === "Video" && (
                  <FileInput fullWidth source="video_url" maxSize={5000000} accept="video/*" placeholder={<p>Drop a file to upload, or click to select it. and upload only 5 mb</p>}>
                    <FileField source="src" title="title" />
                  </FileInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.urltype === "Youtube" && (
                  <TextInput label="Youtube" source="youtube_url" fullWidth />
                )
              }
            </FormDataConsumer>
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};
export default LanguageVIdeoCreate;
