import React, { Fragment } from 'react'
import { List, Datagrid, Filter, ShowButton, TextInput, TextField, EditButton,Button, BulkDeleteButton, FunctionField, DeleteButton } from 'react-admin'
import { ShopStatusSelectInput } from '../../UI/Input/SelectInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ShopStatusSelectInput label="Status" source="status" alwaysOn />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ShopList = ({ ...props }) => {

    return (
        <List {...props} title="Shop"  filters={<ListFilter />} sort={{ field: 'created_at', order: 'DESC' }} >

            <Datagrid bulkActionButtons={<ListActionButtons />} >
                <TextField source="name" label="Shop Name" />
                <TextField source="email" label="Shop Email" />
                <TextField source="phone" label="Shop Phone" />
                <TextField source="gst_number" label="GST Number" />
                <TextField source="owner_name" label="Owner Name" />
                <TextField source="owner_email" label="Owner Email" />
                <TextField source="owner_phone" label="Owner Phone" />
                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Deactive') {
                        return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
                    } else if (record['status'] === 'Pending') {
                        return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
                    } else if (record['status'] === 'Active') {
                        return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
                    } else if (record['status'] === 'Block') {
                        return (<Button style={{ color: 'blue', border: '1px solid' }} label="Block" size="small"></Button>)
                    }else if (record['status'] === 'Suspended'){
                        return (<Button style={{ color: 'black', border: '1px solid' }} label="Suspended" size="small"></Button>)
                    }
                }} />
                <EditButton />
                <ShowButton />
            </Datagrid>

        </List>
    )
}


export default ShopList