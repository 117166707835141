import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  useNotify,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
  ImageInput,
  ImageField,
  Button,
  ArrayInput,
  SimpleFormIterator, useRecordContext
} from "react-admin";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DocumentIcon from '@material-ui/icons/Description';
import PhotoIcon from '@material-ui/icons/InsertPhoto';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { ShopStatusSelectInput } from "../../UI/Input/SelectInput";
import { onShopEdit } from "../../Service/Validation/ShopValidation";
import Grid from "@material-ui/core/Grid";
import ShopImageDelete from './Shop.Images.Delete';
//import ShopImagesField from './Shop.Images.Field'
const PageTitle = () => {
  const record = useRecordContext();
  return <span>Shop: {record ? record.name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const ShopImagesField = ({ source, onItemDelete, }) => {
  const record = useRecordContext();
  const classes = shopPhotosStyles();
  return (<div><h4>Exsisting Shop Photos</h4>
    <div className={classes.root}>

      <GridList className={classes.gridList} cols={6}>
        {record[source] && record[source].map((item) => (
          <GridListTile className={classes.gridTile} key={item.key}>
            <img src={item.url} alt="image" />
            <GridListTileBar classes={{ root: classes.titleBar, title: classes.title, }} actionIcon={<Button aria-label="Delete" onClick={() => { onItemDelete(item) }}><DeleteIcon className={classes.title} /></Button>}></GridListTileBar>
          </GridListTile>
        ))}
      </GridList>
    </div></div>)
}

const ShopEdit = (props) => {

  const [photos, setShopPhotos] = React.useState(null);

  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <TabbedForm warnWhenUnsavedChanges
        toolbar={<FormToolbar />}
        defaultValues={{ action: "full" }}
        validate={onShopEdit}
      >
        <TabbedForm.Tab label="Summary" >
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Email" source="email" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Phone" source="phone" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="City" source="city" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="State" source="state" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Country" source="country" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="GST Number" source="gst_number" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Address" source="address" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Owner name" source="owner_name" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Owner phone" source="owner_phone" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Owner email" source="owner_email" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Owner address" source="owner_address" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Customer discount" source="customer_discount" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="SBO margin" source="sbo_margin" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Description" source="description" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <ShopStatusSelectInput label="Status" source="status" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Latitude" source="latitude" fullWidth />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <TextInput label="Longitude" source="longitude" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={4} lg={4}>
              <ImageInput source="shop_profile_photo" label="shop profile photo" accept="image/*" maxSize={2000000}>
                <ImageField source="src" title="title" />
              </ImageInput>
              <ImageField source="profileimage_url" title="Profile Photos" />
            </Grid>
          </SanitizedGrid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Password" path="password">
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Password" source="password" type="password" fullWidth />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Confirm Password" source="confirmpassword" type="password" fullWidth />
            </Grid>
          </SanitizedGrid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Shop Photos" path="shopphotos">
          <ShopImagesField source="photos" label="Shop Existing Photos" addLabel={true} onItemDelete={(item) => { setShopPhotos(item) }} />
          <ShopImageDelete photos={photos} onClose={() => { setShopPhotos(null) }} />
          <ImageInput source="photos" label="Upload Shop Photos" accept="image/*" multiple>
            <ImageField source="src" title="title" />
          </ImageInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Working Hours" path="workinghours">
          {<ul style={{paddingLeft:0}}>
            <ol style={{paddingLeft:0}}>Please give day for this format : Ex Sun,Mon,Tue,Wed,Thu,Fri,Sat</ol>
            <ol style={{paddingLeft:0}}>Please give time for 12 hrs format : Ex 9:00 AM to 10 PM</ol>
          </ul>}
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={12} lg={12}>
              <ArrayInput source="working_hours" label="Working Hours">
                <SimpleFormIterator inline>
                  <TextInput source="day" label="Day" />
                  <TextInput source="start_time" label="Start Time" />
                  <TextInput source="end_time" label="End Time" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </SanitizedGrid>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
const shopPhotosStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.secondary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  gridTile: {
    width: '200px!important',
    height: '200px!important',
  },
}));
export default ShopEdit;
