

export const onArticleCreate = (values) => {

    const errors = {}
     if (required(values.page_title)) {
        errors.page_title = 'Page Title is required'
    } 
    if (required(values.image)) {
        errors.image = 'Image required'
    }
    if (required(values.bvideo)) {
        errors.bvideo = 'Before Description Video required'
    }

    // if (required(values.description)) {
    //     errors.description = 'Description required'
    // }

    if (required(values.avideo )) {
        errors.avideo  = 'After Description Video required'
    }
    if (required(values.status )) {
        errors.status  = 'Status required'
    }

    return errors;
   
};

export const onArticleEdit = (values) => {

    const errors = {}
    if (required(values.page_title)) {
        errors.page_title = 'Page Title is required'
    } 
    if (required(values.image)) {
        errors.image = 'Image required'
    }
    if (required(values.bvideo)) {
        errors.bvideo = 'Before Description Video required'
    }

    // if (required(values.description)) {
    //     errors.description = 'Description required'
    // }

    if (required(values.avideo )) {
        errors.avideo  = 'After Description Video required'
    }
    if (required(values.status )) {
        errors.status  = 'Status required'
    }
    return errors;
   
};

















const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}


