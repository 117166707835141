import React,{Fragment} from "react";
import {
  List,
  Datagrid,
  Filter,
  EditButton,
  Button,
  useRecordContext,
  Link,
  TextInput,
  TextField,
  FunctionField,
  ShowButton
} from "react-admin";
import BulkActiveButton from '../PlanUpgradeRequest/Action/Bulk.Active.Button';
import BulkRejectButton from '../PlanUpgradeRequest/Action/Bulk.Reject.Button';
import {PlanUpgradeRequestStatusSelectInput} from '../../UI/Input/SelectInput'

const BulkActionButtons = ({  ...props }) => {

  return (<Fragment>
      <BulkActiveButton label="Bulck Active"  {...props} />

      <BulkRejectButton label="Bulck Reject"  {...props} />

  </Fragment>)
}
const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}
const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
    <PlanUpgradeRequestStatusSelectInput label="Status" source="req_status" alwaysOn/>  
  </Filter>
);

const PlanUpgradeRequestList = ({ ...props }) => {
 
  return (
    <Fragment>
    <List
      {...props}
      title="Plan Upgrade Request"
      bulkActionButtons={<BulkActionButtons />}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id" label="Profile Id" /> 
        <TextField source="req_datetime" label="Request Date" /> 
        <FunctionField source="req_status" label="Status" render={record => {  
           if(record['req_status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['req_status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }else if(record['req_status'] === 'Cancelled'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} /> 
           <FunctionField source="req_status" label="Edit Action" render={record => {  
            if(record['req_status'] === 'Pending') {
              return (  <EditButton/>  )            
            }                         
          }} /> 
                <ShowButton/>
      </Datagrid>
    </List>
      
       </Fragment>
  );
};

export default PlanUpgradeRequestList;
