import React from 'react'
import { Button, useRefresh, useNotify, useRecordContext } from 'react-admin'
import IconBlock from '@material-ui/icons/Block'
import { singleApprovePublicCloudStorage } from '../../../Model/publicCloudStorage'


const handleAssignClick = (refresh, notify, record) => {
   
  
    const data = {
        "sid": record.id,
    }
    singleApprovePublicCloudStorage(data, (res) => {
        refresh()
        notify(res.message)
    }, (res) => {
        notify(res.message, 'warning')
    })

}

const ApproveButton = ({ selectedIds, resource, }) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext();
   
    return (
        <Button label="Approve" style={{ color:'green',border:'1px solid'}} icon={<IconBlock />} onClick={() => {
            handleAssignClick(refresh, notify,record)
        }} />
    );
}

export default ApproveButton