import React from 'react'
import { Button, useRefresh, useNotify, useUnselectAll, } from 'react-admin'
import IconBlock from '@material-ui/icons/Block';
import { bulkSendWelcomeBonusWithdraw } from '../../../Model/bulkSendWelcomeBonusWithdraw';


const handleAssignClick = (selectedIds, resource, refresh, notify, unselectAll) => {
    const data = {
        "sid[]": selectedIds,
    }
    bulkSendWelcomeBonusWithdraw(data, (res) => {
        refresh()
        unselectAll(resource)
        notify(res.message)
    }, (res) => {
        notify(res.message, 'warning')
    })

}

const BulkActiveButton = ({ selectedIds, resource }) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const unselectAll = useUnselectAll()
    return (
        <Button label="Bulk send" style={{ color:'green',border:'1px solid'}} icon={<IconBlock />} onClick={() => {
            handleAssignClick(selectedIds, resource, refresh, notify, unselectAll)
        }} />
    );
}

export default BulkActiveButton