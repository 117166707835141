import React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onBasicPlanEdit } from "../../Service/Validation/BasicPlanValidation";
import Grid from "@material-ui/core/Grid";

const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const PageTitle = ({ record }) => {
  return <span>Basic Plan: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const BasicPlanEdit = (props) => {
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onBasicPlanEdit} redirect="list">
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Plan Name" source="plan_name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Plan Amount" source="plan_amount" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Video Amount" source="video_amount" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Video Count" source="video_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Status2SelectInput label="Status" source="status" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Public Video Count" source="public_video_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <NumberInput label="Public Video Amount" source="public_video_amount" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default BasicPlanEdit;
