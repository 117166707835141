export const onShopBankEdit = (values) => {

    const errors = {}

    if (required(values.account_no)) {
        errors.account_no = 'Account number required'
    }    
    
    if (required(values.shop_id)) {
        errors.shop_id = ['Shop required']
    }
    if (required(values.ifsc)) {
        errors.ifsc = ['IFSC required']
    }
    if (required(values.bank_name)) {
        errors.bank_name = ['Bank name required']
    }
    if (required(values.account_holder_name)) {
        errors.account_holder_name = ['Account holder name required']
    }
    if (required(values.branch)) {
        errors.branch = ['Branch name required']
    }
  
    return errors;

};

export const onShopBankCreate = (values) => {

    const errors = {}
    if (required(values.account_no)) {
        errors.account_no = 'Account number required'
    }   
    if (required(values.shop_id)) {
        errors.shop_id = ['Shop required']
    }
    if (required(values.ifsc)) {
        errors.ifsc = ['IFSC required']
    }
    if (required(values.bank_name)) {
        errors.bank_name = ['Bank name required']
    }
    if (required(values.account_holder_name)) {
        errors.account_holder_name = ['Account holder name required']
    }
    if (required(values.branch)) {
        errors.branch = ['Branch name required']
    }   
    return errors;
    

};
const isEmpty = (value: any) => {
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) => {
    return isEmpty(value)
}