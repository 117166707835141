import React from 'react'
import clsx from 'clsx';
import { Card, CardHeader, Grid, Avatar, Typography, } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { People as IconUser, WorkOutline as IconProject, CardGiftcard as IconGift } from '@material-ui/icons'
import { green, blue, pink, orange } from '@material-ui/core/colors'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import IconPlot from '@material-ui/icons/ViewQuiltOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WalletIcon from '@mui/icons-material/Wallet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


const DashboardCounts = ({ classes, counts, orgid, ...props }) => {
    var colsize = orgid == 1 ? 3 : 4;
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorgreen)}><IconUser style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Total Users</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.user.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><GroupAddIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Active Users</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.activeuser.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><PersonAddDisabledIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Suspended Users</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.suspendeduser.all}</Typography>}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororblue)}><GroupRemoveIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Pending Users</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.pendinguser.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorgreen)}><PeopleAltIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Pending Bulk Users</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.pending_bulkuser.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><WalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Video Watching Income</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.working_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Non Working Wallet</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.nonworking_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororblue)}><AssignmentIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Article Reading Income</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.article_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorgreen)}><CleanHandsIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Oppinion Value Income </Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.oppinion_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><IconUser style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">TL Bonus Income</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.tlbonus_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Intro Bonus Income</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.introbonus_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorblue)}><CleanHandsIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">BuyBack Points </Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.buyback_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">W - N/W Request Amount</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.withdrawalrequest_amount_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><IconUser style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Welcome Bonus</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.welcome_bonus_wallet_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Referral Income</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{0}</Typography>}
                        />
                    </Card>
                </Grid>

            </Grid>


            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Working Request Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.videowithdrawalrequest_count.all}</Typography>}
                        />
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><GroupRemoveIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Non-Working Request Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.userwithdrawalrequest_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AssignmentIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Welcome Request Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.welcomerequest_count.all}</Typography>}
                        />
                    </Card>
                </Grid>


            </Grid>



            <Grid container spacing={2}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Affliate Amount</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.affiliated_wallet_amount.all}</Typography>}
                        />
                    </Card>
                </Grid>




            </Grid>
            <br></br>
            <hr></hr>
            <h2>SBO Merchandiser Dashboard......!</h2>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorgreen)}><GroupRemoveIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Total Active Merchandiser</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.total_merchandiser_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorblue)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Total Basic Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.total_basic_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorblue)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Total Premium Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.total_premium_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorblue)}><AccountBalanceWalletIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Available Basic Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.available_basic_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Available Premium Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.available_premium_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colororange)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Active Basic User Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.active_basic_user_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorblue)}><AssignmentIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Active Premium User Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.active_premium_user_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorgreen)}><CleanHandsIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Pending Basic User Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.pending_basic_user_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardheader}
                            avatar={<Avatar className={clsx(classes.counticons, classes.colorpink)}><AddShoppingCartIcon style={{ fontSize: 40 }} /></Avatar>}
                            title={<Typography component="h2" variant="h6" align="right">Pending Premium User Count</Typography>}
                            subheader={<Typography component="h2" variant="h5" align="right">{counts.pending_basic_user_count.all}</Typography>}
                        />
                    </Card>
                </Grid>
            </Grid>


        </div>
    )
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 0,
        marginTop: 40,
        marginBottom: 40,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    card: {
        overflow: 'visible',
        padding: 3,
        margin: 'auto',
    },
    grid: {
        margin: 'auto',
        padding: 0,
        minHeight: 103,
    },
    cardheader: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    counticons: {
        width: 60, height: 60, marginTop: -20
    },
    colorgreen: { backgroundColor: green[500] },
    colorpink: { backgroundColor: pink[500] },
    colorblue: { backgroundColor: blue[500] },
    colororange: { backgroundColor: orange[400] },
    colororblue: { backgroundColor: blue[700] }
});


export default withStyles(styles)(DashboardCounts)