import React from 'react'
import { Card, CardHeader, List, ListItem, ListItemText, Typography,ListItemSecondaryAction,Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const DashboardListActiveUser = ({ classes, items, dataLoading,...props }) => {
    return (
        <Card className={classes.card}>
            <CardHeader className={classes.cardheader} title={<Typography component="h3" variant="h6">Active Users</Typography>}/>
                {dataLoading && <p style={{textAlign:'center'}}>Loading...</p>}
                {!dataLoading && items && items.length<=0 && <p style={{textAlign:'center'}}>No items found</p>}
                {items && <List className={classes.root}>
                    {items.map((item)=>{
                        return( 
                            <ListItem className={classes.listitem} key={item.id} button to={`/user?filter={"q":"${item.id}"}`} component={Link}>                                
                                <ListItemText primary={item.name} secondary={item.created} />
                                <ListItemSecondaryAction>
                                    <Chip label={item.profile_id}/>
                                </ListItemSecondaryAction>
                            </ListItem>                            
                        )
                    })}
                </List>}
        </Card>
    )
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {},
    cardheader:{ borderBottom:1, borderBottomColor:'#e4e4e4', borderBottomStyle:'solid' },
    listitem:{ borderBottom:1, borderBottomColor:'#eeeeee', borderBottomStyle:'solid' },
});


export default withStyles(styles)(DashboardListActiveUser)