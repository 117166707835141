import React  from 'react'
import { Button, useRefresh, useNotify, useUnselectAll, } from 'react-admin'
import IconBlock from '@material-ui/icons/Block'
import { removeAssignKeyword } from '../../../Model/Shop'


const handleAssignClick = (shopid, selectedIds, resource, refresh, notify, unselectAll) => {
    const data = {
        "shop_id":shopid,
        "sid[]":selectedIds,
    }
    removeAssignKeyword(data,shopid, (res) => {
        refresh()
        unselectAll(resource)
        notify(res.message)
    }, (res) => {
        notify(res.message,'warning')
    })
}

const RemoveAssignedKeywordButton = ({shopid, selectedIds, resource })=> {
    const refresh = useRefresh()
    const notify = useNotify()
    const unselectAll = useUnselectAll()
    return (
        <Button label="Remove Assigned Keyword" color="secondary" icon={<IconBlock />} onClick={()=>{
            handleAssignClick(shopid, selectedIds, resource, refresh, notify, unselectAll)
        }} />
    );
}

export default RemoveAssignedKeywordButton