import React from "react";
import {
  Edit,
  SimpleForm,
 
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onCodeEdit } from "../../Service/Validation/CodeValidation";
import Grid from "@material-ui/core/Grid";

const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const PageTitle = ({ record }) => {
  return <span>Code: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const CodeEdit = (props) => {  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
            <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar/>} defaultValues={{ action: "full" }} validate={onCodeEdit} redirect="list">                
            <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={6} lg={6}>
            <TextInput label="Code" source="code" fullWidth/>
            </Grid>
          
<Grid item sm={12} md={6} lg={6}>
<Status2SelectInput label="Status" source="status" fullWidth/>
</Grid>
</SanitizedGrid> 
            </SimpleForm>
        </Edit>
  );
};

export default CodeEdit;
