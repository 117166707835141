import React from 'react'
import { Button, useRefresh, useNotify, useRecordContext, } from 'react-admin'
import IconBlock from '@material-ui/icons/Block'
import { singleRejectPublicCloudStorage } from '../../../Model/publicCloudStorage'


const handleAssignClick = (refresh, notify, record) => {
    const data = {
        "sid": record.id,
    }
    singleRejectPublicCloudStorage(data, (res) => {
        refresh()
        notify(res.message)
    }, (res) => {
        notify(res.message, 'warning')
    })

}

const RejectButton = ({ selectedIds, resource }) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext();
    return (
        <Button label="Reject" style={{ color:'red',border:'1px solid'}} icon={<IconBlock />} onClick={() => {
            handleAssignClick(refresh, notify,record)
        }} />
    );
}

export default RejectButton