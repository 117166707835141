import * as React from "react";
import { Show, ShowController, ReferenceManyField, Pagination, TabbedShowLayout, Datagrid, List, RichTextField, Tab, useRecordContext, Button, TextField, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const PostPagination = ({ record, ...props }) => {
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 50, 100, { value: props.total, label: 'All' }]} {...props} />)
}
const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}
const SponsorProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.user_sponsorid}"}`} onClick={(event)=>event.stopPropagation()} />
)}
const SponsorProfileidFiledTwo = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.sponsor_profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}
const UserDetail = ({ ...props }) => {
  const classes = useStyles();
  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show title="User Detail "  {...props}>
          {console.log(controllerProps)}
          <TabbedShowLayout>
            <Tab label="Summary">
              <TextField source="id" />
              <TextField source="name" label="Name" />
              {/* <TextField source="profile_id" label="Profile ID" /> */}
              <ProfileidFiled source="profile_id" label="Profile Id" /> 
              <TextField source="user_sponsorid" label="Sponsor Id" />
              <TextField source="user_sponsorname" label="Sponsor Name" />
              <TextField source="email" label="Email" />
              <TextField source="phone" label="Phone" />
              <TextField source="password" label="password" />
              <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Decline') {
                  return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
                } else if (record['status'] === 'Pending') {
                  return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
                } else if (record['status'] === 'Active') {
                  return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
                } else if (record['status'] === 'Suspended') {
                  return (<Button style={{ color: 'blue', border: '1px solid' }} label="Suspended" size="small"></Button>)
                }else if (record['status'] === 'Blocked') {
                  return (<Button style={{ color: 'purple', border: '1px solid' }} label="Blocked" size="small"></Button>)
                }
              }} />
              <TextField source="reason" label="Reason" />
              <FunctionField source="declineby.name" label="Decline By" render={record => {
                if (record['join_type'] === 'Teamleader') {
                  return (<TextField source="declineby" label="Decline By" />)
                } else {
                  return (<TextField source="declineby.name" label="Decline By" />)
                }
              }} />
              <TextField source="suspendedby.name" label="Suspended By" />
              <FunctionField source="approvedby.name" label="Approved By" render={record => {
                if (record['join_type'] === 'Teamleader') {
                  return (<TextField source="approvedby" label="Decline By" />)
                } else {
                  return (<TextField source="approvedby.name" label="Approved By" />)
                }
              }} />

              <TextField source="approved_date" label="Approved Date" />
              <TextField source="basic_user_count" label="Basic User Count" />
              <TextField source="premium_user_count" label="Premium User Count" />
              <TextField source="created_at" label="Created Date" />
              <TextField source="updated_at" label="Updated Date" />
              <FunctionField source="status" label="Status" render={record => {
                if (record['payment_proofurl']) {
                  return (<span>Payment Proof <br /><br /><img style={{ maxWidth: '150px' }} src={record['payment_proofurl']} alt={record.name} /></span>)
                }
              }} />
              {/*TODO add another detail to user  */}
            </Tab>
            <Tab label="User Basic Info">
              <TextField label="Country" source="userinfo.country" sortable={false} />
              <TextField label="State" source="userinfo.state" sortable={false} />
              <TextField label="City" source="userinfo.city" sortable={false} />
              <TextField label="Address" source="userinfo.address" sortable={false} />              
              <TextField label="Whatsapp" source="userinfo.whatsapp" sortable={false} />
              <TextField label="Pancard" source="userinfo.pancard" sortable={false} />
              <TextField label="DOB" source="userinfo.dob" sortable={false} />
            </Tab>
            <Tab label="Bank Detail" path="userbank">
              <ReferenceManyField pagination={<PostPagination />} reference={'userbank'} target="user_id" label="" filter={{ user_id: controllerProps.id }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  <TextField label="Account Number" source="account_no" sortable={false} />
                  <TextField label="Account Holder Name" source="account_holder_name" sortable={false} />
                  <TextField label="IFSC" source="ifsc" sortable={false} />
                  <TextField label="Bank Name" source="bank_name" sortable={false} />
                  <RichTextField label="Branch" source="branch" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="SBO Merchandiser" path="sbomerchandiserid">
              <ReferenceManyField pagination={<PostPagination />} reference={'sbomerchandiserid'} target="id"  filter={{ p_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />  
                  {/* <TextField source="user_sponsorid" />    */}
                  <TextField source="name" label="Name" />
                  <ProfileidFiled label="Profile id" source="profile_id"/>
                  <SponsorProfileidFiled source="user_sponsorid" label="Sponsor Id" />  
                  <TextField source="user_sponsorname" label="Sponsor Name" />                       
                  <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Decline') {
                      return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
                    } else if (record['status'] === 'Pending') {
                      return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
                    } else if (record['status'] === 'Active') {
                      return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
                    } else if (record['status'] === 'Suspended') {
                      return (<Button style={{ color: 'blue', border: '1px solid' }} label="Suspended" size="small"></Button>)
                    }
                  }} />          
                  <TextField source="created_at" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Unused Links" path="generatedlinks">
              <ReferenceManyField pagination={<PostPagination />} reference={'generatedlinks'} target="id"  filter={{ p_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />  
                  <SponsorProfileidFiledTwo source="sponsor_profile_id" label="Sponsor Id" />
                  <TextField source="plan_text" label="Plan Name" />  
                  <TextField source="link" label="Link" /> 
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Withdrawal Request">
              <ReferenceManyField pagination={<PostPagination />} reference={'withdrawalhistory'} target="user_id" label="" filter={{ user_id: controllerProps.id }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  <TextField label="Request CVAmount" source="req_cvamount" sortable={false} />
                  <TextField label="Total Amount" source="req_cvamount" sortable={false} />
                  <TextField label="Datetime" source="req_datetime" sortable={false} />
                  <TextField label="Status" source="req_status" sortable={false} />
                  <RichTextField label="Type" source="req_type" sortable={false} />
                  <TextField source="approvedby.name" label="Approved" />
                  <TextField source="declineby.name" label="Decline" />
                  <TextField label="Reason" source="decline_reason" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Reward History">
              <ReferenceManyField pagination={<PostPagination />} reference={'rewardhistory'} target="user_id" label="" filter={{ user_id: controllerProps.id }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Created Date" source="created_date" sortable={false} />
                  <TextField label="Total Reward" source="total_reward" sortable={false} />
                  <TextField label="Current Plan" source="current_plan" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Wallet Details">
              <ReferenceManyField pagination={<PostPagination />} reference={'wallethistory'} target="user_id" label="" filter={{ user_id: controllerProps.id }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="User Wallet" source="user_wallet" sortable={false} />
                  <TextField label="Video Wallet" source="video_wallet" sortable={false} />
                  <TextField label="Total Earned" source="user.total_earned" sortable={false} />
                  <TextField label="Affiliate AC Balance" source="transffered_amount" sortable={false} />
                  <TextField label="Reward Wallet" source="reward.total_reward" sortable={false} />
                  <TextField source="utr" label="UTR Number" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Payout History">
              <ReferenceManyField pagination={<PostPagination />} reference={'payouthistory'} target="id" label="" filter={{ p_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  <TextField label="Pay From" source="pay_from" sortable={false} />
                  <TextField label="Bonus Type" source="bonus_type" sortable={false} />
                  <TextField label="Description" source="description" sortable={false} />
                  <TextField label="Amount" source="amount" sortable={false} />
                  <TextField label="Pay Date" source="pay_date" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Buy Back History">
              <ReferenceManyField pagination={<PostPagination />} reference={'buybackpointhistory'} target="id" label="" filter={{ profile_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Buy Back Point" source="buyback_point" sortable={false} />
                  <TextField label="Created Date" source="created_date" sortable={false} />
                  <TextField label="Description" source="description" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Welcome Bonus History">
              <ReferenceManyField pagination={<PostPagination />} reference={'welcomebonushistory'} target="id" label="" filter={{ profile_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Welcome Bonus" source="welcome_bonus" sortable={false} />
                  <TextField label="Created Date" source="created_date" sortable={false} />
                  <TextField label="Description" source="description" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Purchased user">
              <ReferenceManyField pagination={<PostPagination />} reference={'purchaseduser'} target="id" label="" filter={{ p_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid bulkaction={null} classes={{ row: classes.row }} >
                  <TextField source="id" />
                  <TextField label="Name" source="name" sortable={false} />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Sponsor id" source="user_sponsorid" sortable={false} />
                  <TextField label="Email" source="email" sortable={false} />
                  <TextField label="Phone" source="phone" sortable={false} />
                  <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Decline') {
                      return (<Button style={{ color: 'red', border: '1px solid' }} label="Decline" size="small"></Button>)
                    } else if (record['status'] === 'Pending') {
                      return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
                    } else if (record['status'] === 'Active') {
                      return (<Button style={{ color: 'green', border: '1px solid' }} label="Active" size="small"></Button>)
                    } else if (record['status'] === 'Suspended') {
                      return (<Button style={{ color: 'blue', border: '1px solid' }} label="Suspended" size="small"></Button>)
                    }
                  }} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            <Tab label="Welcome Bonus">
              <ReferenceManyField pagination={<PostPagination />} reference={'welcomebonushistory'} target="id" label="" filter={{ profile_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid bulkaction={null} classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Welcome Bonus" source="welcome_bonus" sortable={false} />
                  <TextField label="Created Date" source="created_date" sortable={false} />
                  <TextField label="Description" source="description" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            <Tab label="Buy Back History">
              <ReferenceManyField pagination={<PostPagination />} reference={'buybackpointhistory'} target="id" label="" filter={{ profile_id: controllerProps.record ? controllerProps.record.profile_id : 0 }} addLabel={false} >
                <Datagrid bulkaction={null} classes={{ row: classes.row }} >
                  <TextField source="id" />
                  {/* <TextField label="Profile id" source="profile_id" sortable={false} /> */}
                  <ProfileidFiled source="profile_id" label="Profile Id" /> 
                  <TextField label="Buy Back Point" source="buyback_point" sortable={false} />
                  <TextField label="Created Date" source="created_date" sortable={false} />
                  <TextField label="Description" source="description" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        </Show>
      }
    </ShowController>
  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default UserDetail