import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onBasicPlanCreate } from "../../Service/Validation/BasicPlanValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Basic Plan</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const BasicPlanCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onBasicPlanCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Plan Name" source="plan_name" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Plan Amount" source="plan_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Video Amount" source="video_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Video Count" source="video_count" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <Status2SelectInput label="Status" source="status" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>      
          <NumberInput label="Public Video Count" source="public_video_count" fullWidth/>
        </Grid>  
        <Grid item sm={12} md={4} lg={4}>      
          <NumberInput label="Public Video Amount" source="public_video_amount" fullWidth/>
        </Grid>  
          
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default BasicPlanCreate;
