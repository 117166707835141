import React from "react";
import {
  Edit,
  SimpleForm,
  FormTab,
  useNotify,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { ContentWriterKeywordStatusSelectInput } from "../../UI/Input/SelectInput";
import { onContentWriterKeywordAmountEdit } from "../../Service/Validation/ContentWriterKeywordAmountValidation";
import Grid from "@material-ui/core/Grid";
const PageTitle = ({ record }) => {
  return <span>Content Writer Keyword Amount: {record ? record.id : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const ContentWriterKeywordAmountEdit = (props) => {
  const notify = useNotify();

  const onError = (error) => {
    var errorArr = [];
    Object.keys(error.body.errors).forEach(function (key) {
      errorArr.push(error.body.errors[key]);
    });
    notify(`Could not create item: ${errorArr.toString()}`, { type: "error" });
  };
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges
        toolbar={<FormToolbar />}
        defaultValues={{ action: "full" }}
        validate={onContentWriterKeywordAmountEdit}
      >  <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Keyword Count" source="keyword_count" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Amount" source="amount" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default ContentWriterKeywordAmountEdit;
