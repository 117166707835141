export const onVideoListEdit = (values) => {

    const errors = {}
    
    if (required(values.status)) {
        errors.status = 'Status required'
    }
    if (required(values.video_type)) {
        errors.video_type = 'Type required'
    }
    if (required(values.video_type=='Youtube')) {
        errors.youtube_id = ['Youtube ID required']
    } 

    if (required(values.video_type=='Video')) {
        errors.video = ['Video url required']
    }
    if (required(values.video_name)) {
        errors.video_name = ['Video name required']
    }

    return errors;
   
};

export const onVideoListCreate = (values) => {

    const errors = {}
    if (required(values.status)) { 
        errors.status = ['status is required']
    } 
   
    if (required(values.video_type)) {
        errors.video_type = ['Type required']
    }   

    if (required(values.video_type=='Youtube')) {
        errors.youtube_id = ['Youtube ID required']
    } 

    if (required(values.video_type=='Video')) {
        errors.video = ['Video url required']
    }
    if (required(values.video_name)) {
        errors.video_name = ['Video name required']
    }
    
    return errors;
   
};
const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) =>{
    return isEmpty(value)
}