import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  ImageField,
  FunctionField,
  RichTextField,
  DeleteButton
} from "react-admin";


const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
   
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const ArticleList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Plan"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}
     
    >
      <Datagrid rowClick={"show"}>
      <TextField source="id" />
            <TextField source="page_title" label="Page Title" />
            <ImageField source="imageurl" label="Image" />
            <TextField source="bvideo" label="Before Description Video" />
            {/* <RichTextField  source="description" label="Description" /> */}
            <TextField source="websitelink" label="Website Link" />
            <TextField source="avideo" label="After Description Video" />
            <FunctionField source="status" label="Status" render={record => {
                            if (record['status'] === 'Deactive') {
                                return (<span style={{ color: 'red' }}>Deactive</span>)
                            } else {
                                return (<span style={{ color: 'green' }}>Active</span>)
                            }
                        }} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default ArticleList;
