import * as React from "react";
import { Show, SimpleShowLayout,useRecordContext,Button, TextField,FunctionField } from 'react-admin';
const PageTitle = () => {
    const record = useRecordContext();
    return <span>Plan upgrade request for {record ? `${record.profile_id}` : ''}</span>;
  };
const PlanUpgradeRequestDetail = ({...props }) => (

    <Show title={<PageTitle />} {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="profile_id" label="Profile Id" />       
        <TextField label="Approved by" source="approvedby.name"/> 
        <TextField label="Cancelled by" source="cancelledby.name"/>
        <TextField label="Cancelled Reason" source="cancelled_reason"/>
        <TextField source="req_datetime" label="Request Date" /> 
        <FunctionField source="req_status" label="Status" render={record => {  
           if(record['req_status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['req_status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }else if(record['req_status'] === 'Cancelled'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} />  
        </SimpleShowLayout>
    </Show>
     );
export default PlanUpgradeRequestDetail