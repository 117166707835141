export const onCategoryListEdit = (values) => {

    const errors = {}

    if (required(values.status)) {
        errors.status = 'Status required'
    }
    if (required(values.amount)) {
        errors.amount = 'Amount required'
    }
    if (required(values.name)) {
        errors.name = ['Category name required']
    }
    return errors;

};

export const onCategoryListCreate = (values) => {

    const errors = {}
    if (required(values.status)) {
        errors.status = 'Status required'
    }
    if (required(values.amount)) {
        errors.amount = 'Amount required'
    }
    if (required(values.name)) {
        errors.name = ['Category name required']
    }
    return errors;
    

};
const isEmpty = (value: any) => {
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) => {
    return isEmpty(value)
}