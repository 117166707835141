import React, { Fragment } from 'react'
import { List, Datagrid, Filter, ShowButton, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link } from 'react-admin'
import { ShopSelectInput } from '../../UI/Input/SelectInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ShopSelectInput label="Shop" source="shop_id" fullWidth alwaysOn />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ShopFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/shop/${record.shop_id}/show`} onClick={(event) => event.stopPropagation()} />
    )
}
const ShopBankList = ({ ...props }) => {

    return (
        <List {...props} title="Shop Bank" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'name', order: 'ASC' }}>

            <Datagrid >
                <ShopFiled source="shop.name" label="Shop" />
                <TextField source="account_no" label="Account Number" />
                <TextField source="ifsc" label="IFSC" />
                <TextField source="bank_name" label="Bank Name" />
                <TextField source="account_holder_name" label="Account Holder Name" />
                <TextField source="branch" label="Branch Name" />
                <EditButton />
                <DeleteButton />
                <ShowButton />
            </Datagrid>

        </List>
    )
}


export default ShopBankList