import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  TopToolbar ,
  useListContext


} from "react-admin";
import { stringify } from 'query-string';
import { makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import {PaymentMethodSelectInput} from '../../UI/Input/SelectInput'

const ListFilter = (props) => {
 
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <PaymentMethodSelectInput label="Payment method" source="payment_method" alwaysOn/>
   
  </Filter>)
};



const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}



const ListActions = (props) => {
  const record = useListContext();
 const filva = record.filterValues ? stringify(record.filterValues) : ''; 
 
 return (
 <TopToolbar>
    <Button  href={`//sboportal.org.in/admin/api/v1/userutr/export?${filva}`} label="User Utr's Export" ><DownloadIcon /></Button>
    </TopToolbar >)
};




const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const UserUtrList = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="User"
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
      
       actions={<ListActions/>}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/>
        <TextField source="utr" label="UTR Number" />  
        <TextField source="phone" label="Phone" />
        <TextField source="payment_method" label="Payment Method" />
        <TextField source="created_at" label="Submited Date" />
           
       
        </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default UserUtrList;
