import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  EditButton,Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  FunctionField,
  ShowButton,
  TopToolbar ,
  DateInput,
  useListContext


} from "react-admin";
import { makeStyles } from '@material-ui/core';
import BulkActiveButton from '../PendingUser/Action/Bulk.Active.Button';
import BulkRejectButton from '../PendingUser/Action/Bulk.Reject.Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DateRangeInput } from '../../UI/Input/input';
import {PlanSelectInput} from '../../UI/Input/SelectInput'

const ListFilter = (props) => {
  const {
    
    setFilters,
    hideFilter
} = useListContext();
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
   
  
  
    <PlanSelectInput label="Plan" source="plan" alwaysOn/>
    <DateInput source="current_date" lable="Date" alwaysOn/>
    <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
            setFilters({ daterange: strdate })
        }} />
  </Filter>)
};



const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}

const SponsorProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.user_sponsorid}"}`} onClick={(event)=>event.stopPropagation()} />
)}

const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
        <BulkActiveButton label="Bulck Active"  {...props} />
        <BulkRejectButton label="Bulck Reject"  {...props} />
    </Fragment>)
}

const ListActions = () => (
  <TopToolbar>
     <Button  href={'//sboportal.org.in/admin/api/v1/pendinguser/export?'} label="Export" ><DownloadIcon /></Button>
  </TopToolbar>
);

const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const PendingUserList = ({ ...props }) => {
  const classes = useStyles()
  const [userstatusCreate, setUserStatusCreate] = React.useState(null)
  const handleStatusSelect = (record) => {
      setUserStatusCreate({ record: record })
  }

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="User"
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
       bulkActionButtons={<BulkActionButtons />}
       actions={<ListActions/>}
    >
      <Datagrid >
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/>       
        <SponsorProfileidFiled source="user_sponsorid" label="Sponserid" />
        <TextField source="user_sponsorname" label="Sponsername" />          
        <TextField source="email" label="Email" />    
        <TextField source="utr" label="UTR Number" />  
        <FunctionField source="current_plan" label="Current Plan" render={record => {  
          if (record['current_plan'] == 1) {
            return ( <span>Basic - Silver </span>)
          }  else if(record['current_plan'] == 2) {
            return ( <span>Premium - Silver
            </span>)
          }                  
          }} /> 
       
        <FunctionField source="payment proof" label="Payment Proof" render={record => {  
              if (record['payment_proofurl']) {
                return ( <span>Payment Proof <br/><br/><img style={{maxWidth:'100px'}} src={record['payment_proofurl']} alt={record.name}/></span>)
              }                       
            }} />      
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }else if(record['status'] === 'Suspended'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Suspended" size="small"></Button>)
          }                         
          }} /> 
          <EditButton/>
          <ShowButton/>
          {/*<UserStatusActionButton label="Status" cellClassName={classes.actionCell} rec={record} onClick={handleStatusSelect} />)*/}
      </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default PendingUserList;
