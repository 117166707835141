import React from 'react';
import { Create, SimpleForm, TextInput, useNotify,Toolbar, ListButton, SaveButton } from 'react-admin';

import { StatusSelectInput } from '../../UI/Input/SelectInput'
import { onAdminUserCreate } from '../../Service/Validation/AdminUserValidation'
import Grid from '@material-ui/core/Grid';

const PageTitle = () => {
    return <span>Create Admin User</span>;
};

const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Save" redirect="show" submitOnEnter={true} />
        {!props.saving && <ListButton label="Cancel" />}
    </Toolbar>
)
const SanitizedGrid = ({ basePath, ...props }) => {
    return (
        <Grid {...props} />
    );
};

const AdminUserCreate = (props) => {
    const notify = useNotify();

    const onError = (error) => {        
        var errorArr = [];
        Object.keys(error.body.errors).forEach(function(key) {
            errorArr.push(error.body.errors[key])           
        });
        notify(`Could not create item: ${errorArr.toString()}`,{type:'error'});        
    };
    return(
    <Create title={<PageTitle />} {...props} undoable={false} mutationOptions={{ onError }}>
        <SimpleForm toolbar={<FormToolbar />} warnWhenUnsavedChanges validate={onAdminUserCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
                <Grid item sm={12} md={4} lg={4}>
                <TextInput label="Name" source="name" fullWidth/>
                </Grid>
                 <Grid item sm={12} md={4} lg={4}>
                <TextInput label="Email" source="email" fullWidth/>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                <TextInput label="Phone" source="phone" fullWidth/>
                </Grid>
                </SanitizedGrid> 
           
                <SanitizedGrid container spacing={3} fullWidth>
                <Grid item sm={12} md={4} lg={4}>
                <StatusSelectInput label="Status" source="status" fullWidth/>
                </Grid>
                 <Grid item sm={12} md={4} lg={4}>
                 <TextInput label="Password" source="password" type="password" fullWidth/>   
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                <TextInput label="Confirm Password" source="confirmpassword" type="password" fullWidth/>
                </Grid>
                </SanitizedGrid> 
              
            
        </SimpleForm>
    </Create>
);
    }
export default AdminUserCreate