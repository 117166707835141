import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onCodeCreate } from "../../Service/Validation/CodeValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Basic Plan</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const CodeCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onCodeCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Code" source="code" fullWidth/>
        </Grid>
      
        <Grid item sm={12} md={6} lg={6}>
        <Status2SelectInput label="Status" source="status" fullWidth/>
        </Grid>
          
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default CodeCreate;
