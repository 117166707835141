import React from "react";
import {Edit,useRecordContext,required,FormDataConsumer,FunctionField,SimpleForm,TextInput, Toolbar, ListButton,SaveButton} from "react-admin";

import { WithdrawRequestEditStatusSelectInput,WithdrawRequestStatusSelectInput } from "../../UI/Input/SelectInput";

import Grid from "@material-ui/core/Grid";


const PageTitle = () => {
  const record = useRecordContext();
  return <span>Withdraw Request for {record ? `${record.profile_id}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const WelcomeBonusWithdrawRequestEdit = (props) => {
  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />}  redirect="list" defaultValues={{ action: "full" }}>       
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Profile ID" source="profile_id" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Request Cv Amount" source="req_cvamount" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
              <TextInput disabled label="Total Amount" source="total_amount" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Request type" source="req_type" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
            <FunctionField source="req_status" label="Status" render={record => {  
               if(record['req_status'] === 'Pending') {
                return (<WithdrawRequestEditStatusSelectInput validate={required()} label="Status" fullWidth source="req_status"/>)
               }else{
                return (<WithdrawRequestStatusSelectInput disabled fullWidth label="Current_status" source="req_status"/>)
               }
            }} /> 
            </Grid>     
            <Grid item sm={12}  lg={4}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.req_status === "Decline" && (
                  <TextInput source="decline_reason"  label="Reason" fullWidth validate={required()}/>
                )
              }
            </FormDataConsumer>
            
            </Grid>       
          </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default WelcomeBonusWithdrawRequestEdit;
