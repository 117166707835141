import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { onBonusCreate } from "../../Service/Validation/AddBonusValidation";
import Grid from "@material-ui/core/Grid";

import {BonusTypeSelectInput} from '../../UI/Input/SelectInput'

const PageTitle = () => {
  return <span>Add Bonus</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const AddBonusCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onBonusCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Profile ID" source="profile_id" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <BonusTypeSelectInput label="Bonus Type" source="bonus_type" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Amount" source="amount" fullWidth/>
        </Grid>
       
        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Remarks" source="remarks" fullWidth/>
        </Grid>

       
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default AddBonusCreate;
