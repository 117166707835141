import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  useNotify,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton, SimpleForm
} from "react-admin";

import { ShopSelectInput } from "../../UI/Input/SelectInput";
import { onShopBankEdit } from "../../Service/Validation/ShopBankValidation";
import Grid from "@material-ui/core/Grid";
const PageTitle = ({ record }) => {
  return <span>Shop Bank: {record ? record.accholdername : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const ShopBankEdit = (props) => {
  const notify = useNotify();

  const onError = (error) => {
    var errorArr = [];
    Object.keys(error.body.errors).forEach(function (key) {
      errorArr.push(error.body.errors[key]);
    });
    notify(`Could not create item: ${errorArr.toString()}`, { type: "error" });
  };
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges
        toolbar={<FormToolbar />}
        defaultValues={{ action: "full" }}
        validate={onShopBankEdit}
      >
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <ShopSelectInput label="Shop" source="shop_id" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Account Number" source="account_no" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="IFSC" source="ifsc" fullWidth />
          </Grid>
        </SanitizedGrid>
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Account holder name" source="account_holder_name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Branch" source="branch" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput label="Bank Name" source="bank_name" fullWidth />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default ShopBankEdit;
