import React, { Fragment } from "react";
import { Show, TabbedShowLayout, Tab, TextField, ReferenceManyField, List, Datagrid, TextInput,FunctionField, Filter, ShowController } from 'react-admin';
import AssignCategoryButton from '../Shop/Action/Assign.Category.Button';
import RemoveAssignedCategoryButton from '../Shop/Action/Remove.Assign.Category.Button';

import AssignKeywordButton from '../Shop/Action/Assign.Keyword.Button';
import RemoveAssignedKeywordButton from '../Shop/Action/Remove.Assign.Keyword.Button';
const AssignLayoutFilter = (props) => (

    <Filter style={{ margin: '0px 0px 0px 16px' }} {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);
const BulkActionButtons = ({ shop_id, ...props }) => {    
    return (<Fragment>
        <AssignCategoryButton label="Assign Category" shopid={shop_id} {...props} />

    </Fragment>)
}
const BulkActionKeywordButtons = ({ shop_id, ...props }) => {    
    return (<Fragment>
        <AssignKeywordButton label="Assign Keyword" shopid={shop_id} {...props} />

    </Fragment>)
}
const RemoveCategoryButtons = ({ shop_id, ...props }) => {   
    return (<Fragment>
        <RemoveAssignedCategoryButton label="Remove Category" shopid={shop_id} {...props} />
    </Fragment>)
}

const RemoveKeywordButtons = ({ shop_id, ...props }) => {   
    return (<Fragment>
        <RemoveAssignedKeywordButton label="Remove Keyword" shopid={shop_id} {...props} />
    </Fragment>)
}
const ShopDetail = ({ ...props }) => {
    return (<ShowController {...props}>
        {controllerProps =>
            <Show title="Admin User Detail" {...props}>
                <TabbedShowLayout>
                    <Tab label="Summary">
                        <TextField source="id" />
                        <TextField source="name" label="Name" />
                        <TextField source="email" label="Email" />
                        <TextField source="phone" label="Phone" />
                        <TextField source="status" label="Status" />
                        {console.log(controllerProps.record)}
                    </Tab>
                    <Tab label="Assigned Category" path="assignedcategories">
                        <ReferenceManyField reference='categoryassign'  {...props} target="shop_id" addLabel={false}>
                            <Datagrid bulkActionButtons={<RemoveCategoryButtons shop_id={controllerProps.record ? controllerProps.record.id : 0} />} rowClick="toggleSelection">
                                <TextField source="id" label="ID" />
                                <TextField label="Shop" source="shop.name" />
                                <TextField label="Category" source="category.name" />
                                <TextField label="Assigned By" source="assignedby.name" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                    <Tab label="Assign Category" path="assigncategory">
                        <ReferenceManyField reference={'shopcategorylist'} target="shop_id" addLabel={false} {...props}>
                            <Datagrid {...props} bulkActionButtons={<BulkActionButtons shop_id={controllerProps.record ? controllerProps.record.id : 0} />} currentSort={{ field: 'id', order: 'ASC' }} rowClick="toggleSelection">
                                <TextField label="Id" source="id" />
                                <TextField label="Name" source="name" />
                                <FunctionField source="status" label="Status" render={record => {
                                    if (record['status'] === 'Inactive') {
                                        return (<span style={{ color: 'red' }}>Deactive</span>)
                                    } else {
                                        return (<span style={{ color: 'green' }}>Active</span>)
                                    }
                                }} />
                                <TextField label="Description" source="description" />
                                <TextField label="Created ate" source="created_at" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>

                    <Tab label="Assigned Keyword" path="assignedkeywords">
                        <ReferenceManyField reference='keywordassign'  {...props} target="shop_id" addLabel={false}>
                            <Datagrid bulkActionButtons={<RemoveKeywordButtons shop_id={controllerProps.record ? controllerProps.record.id : 0} />} rowClick="toggleSelection">
                                <TextField source="id" label="ID" />
                                <TextField label="Shop" source="shop.name" />
                                <TextField label="Keyword" source="keyword.keyword" />
                                <TextField label="Assigned By" source="assignedby.name" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                    <Tab label="Assign Keyword" path="assignkeyword">
                        <ReferenceManyField reference={'shopkeywordlist'} target="shop_id" addLabel={false} {...props}>
                            <Datagrid {...props} bulkActionButtons={<BulkActionKeywordButtons shop_id={controllerProps.record ? controllerProps.record.id : 0} />} currentSort={{ field: 'id', order: 'ASC' }} rowClick="toggleSelection">
                                <TextField label="Id" source="id" />
                                <TextField label="Name" source="keyword" />
                                <FunctionField source="status" label="Status" render={record => {
                                    if (record['status'] === 'Inactive') {
                                        return (<span style={{ color: 'red' }}>Deactive</span>)
                                    } else {
                                        return (<span style={{ color: 'green' }}>Active</span>)
                                    }
                                }} />
                                <TextField label="Description" source="description" />
                                <TextField label="Created ate" source="created_at" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                </TabbedShowLayout>
            </Show>
        }
    </ShowController>
    )
}

export default ShopDetail