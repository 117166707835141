

export const onBasicSettingsCreate = (values) => {

    const errors = {}
    if (required(values.total_user_count_admin)) {
        errors.total_user_count_admin = 'User count is required'
    } 
    //  if (required(values.userwallet_min_amount)) {
    //     errors.userwallet_min_amount = 'User wallet min amount is required'
    // } 
    // if (required(values.videowallet_min_amount)) {
    //     errors.videowallet_min_amount = 'Video wallet min amount is required'
    // } 
    // if (required(values.admincharge)) {
    //     errors.admincharge = 'Admin charge required'
    // }

    // if (required(values.basic_user_refer_amount)) {
    //     errors.basic_user_refer_amount ='Basic user refer amount required'
    // }

    // if (required(values.primium_user_refer_amont)) {
    //     errors.primium_user_refer_amont ='Primium user refer amount required'
    // }

    // if (required(values.companey_name)) {
    //     errors.companey_name ='Companey name required'
    // }

    // if (required(values.address)) {
    //     errors.address ='Address required'
    // }

    // if (required(values.email)) {
    //     errors.email = 'Email is required';
    // }else if (email(values.email)) {
    //     errors.email = 'Valid email id required';
    // }

    // if (required(values.phone)) {
    //     errors.phone = 'Phone is required';
    // }else if(maxLength(10,values.phone)){
    //     errors.phone = 'Phone number max 10 digit';
    // }else if(mobile(values.phone)){
    //     errors.phone = 'Phone number numeric only';
    // }
    // if (required(values.points_per_rupee)) {
    //     errors.points_per_rupee = 'Field is required';
    // }
    // if (required(values.reward_percentage)) {
    //     errors.reward_percentage = 'Field is required';
    // }
    // if (required(values.refferal_amount_basic)) {
    //     errors.refferal_amount_basic = 'Field is required';
    // }
    // if (required(values.refferal_amount_premium)) {
    //     errors.refferal_amount_premium = 'Field is required';
    // }

    return errors;
   
};

export const onBasicSettingsEdit = (values) => {

    const errors = {}
    if (required(values.total_user_count_admin)) {
        errors.total_user_count_admin = 'User count is required'
    } 
    // if (required(values.userwallet_min_amount)) {
    //     errors.userwallet_min_amount = 'User wallet min amount is required'
    // } 
    // if (required(values.videowallet_min_amount)) {
    //     errors.videowallet_min_amount = 'Video wallet min amount is required'
    // } 
    // if (required(values.admincharge)) {
    //     errors.admincharge = 'Admin charge required'
    // }

    // if (required(values.basic_user_refer_amount)) {
    //     errors.basic_user_refer_amount ='Basic user refer amount required'
    // }

    // if (required(values.primium_user_refer_amont)) {
    //     errors.primium_user_refer_amont ='Primium user refer amount required'
    // }

    // if (required(values.companey_name)) {
    //     errors.companey_name ='Companey name required'
    // }

    // if (required(values.address)) {
    //     errors.address ='Address required'
    // }

    // if (required(values.email)) {
    //     errors.email = 'Email is required';
    // }else if (email(values.email)) {
    //     errors.email = 'Valid email id required';
    // }

    // if (required(values.phone)) {
    //     errors.phone = 'Phone is required';
    // }else if(maxLength(10,values.phone)){
    //     errors.phone = 'Phone number max 10 digit';
    // }else if(mobile(values.phone)){
    //     errors.phone = 'Phone number numeric only';
    // }
    // if (required(values.points_per_rupee)) {
    //     errors.points_per_rupee = 'Field is required';
    // }
    // if (required(values.reward_percentage)) {
    //     errors.reward_percentage = 'Field is required';
    // }
    // if (required(values.refferal_amount_basic)) {
    //     errors.refferal_amount_basic = 'Field is required';
    // }
    // if (required(values.refferal_amount_premium)) {
    //     errors.refferal_amount_premium = 'Field is required';
    // }
    return errors;
   
};



















const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_MOBILE = /^([0-9\s\-\+\(\)]*)$/
const REGEX_LETTER = /^[A-Za-z]+$/

const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}

export const minLength = (length, value) =>{
    return (!isEmpty(value) && value.length < length)
}

export const maxLength = (length, value) =>{
    return (!isEmpty(value) && value.length > length)
}

export const email = (value) =>{
    return regex(REGEX_EMAIL, value)
}

export const mobile = (value) =>{
    return regex(REGEX_MOBILE, value)
}
export const regex = (pattern, value) =>{
    return (!isEmpty(value) && typeof value === 'string' && !pattern.test(value))
}