import * as React from "react";
import { Show, SimpleShowLayout,useRecordContext,Button,UrlField , TextField,FunctionField } from 'react-admin';
const PageTitle = () => {
    const record = useRecordContext();
    return <span>Digital Task Application for {record ? `${record.profile_id}` : ''}</span>;
  };
const DigitalTaskApplicationDetail = ({...props }) => (

    <Show title={<PageTitle />} {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="profile_id" label="Profile Id" />
        <TextField source="user_name" label="User Name" />
        <TextField source="name" label="Name" />  
        <UrlField  source="url" label="URL" />      
        <TextField source="category" label="Category" />      
        <TextField source="noofpost" label="NO of Post" />     
        <TextField source="type" label="Type" />   
        <TextField label="Source Created Date" source="source_created_date"/> 
        <TextField label="Approved by" source="approvedby.name"/> 
        <TextField label="Decline by" source="declineby.name"/>
        <TextField label="Reason" source="reason"/>
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Rejected') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Rejected" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }else if(record['status'] === 'Cancel'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} /> 
        </SimpleShowLayout>
    </Show>
     );
export default DigitalTaskApplicationDetail