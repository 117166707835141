

export const onUpgradePlanCreate = (values) => {

    const errors = {}
     if (required(values.plan_name)) {
        errors.plan_name = 'Plan name is required'
    } 
    
    if (required(values.video_amount)) {
        errors.video_amount = 'Video amount required'
    }

    if (required(values.video_count)) {
        errors.video_count = 'Video count required'
    }

    if (required(values.status )) {
        errors.status  = 'Status required'
    }
    if (required(values.basic_plan_id )) {
        errors.basic_plan_id  = 'Basic plan required'
    }

    if (required(values.eligible_member_count )) {
        errors.eligible_member_count  = 'Eligible member count required'
    }

    if (required(values.public_video_amount)) {
        errors.public_video_amount = 'Public video amount required'
    }

    if (required(values.public_video_count)) {
        errors.public_video_count = 'Public video count required'
    }

    

    return errors;
   
};

export const onUpgradePlanEdit = (values) => {

    const errors = {}
    if (required(values.plan_name)) {
        errors.plan_name = 'Plan name is required'
    } 
    
    if (required(values.video_amount)) {
        errors.video_amount = 'Video amount required'
    }

    if (required(values.video_count)) {
        errors.video_count = 'Video count required'
    }

    if (required(values.status )) {
        errors.status  = 'Status required'
    }

    if (required(values.basic_plan_id )) {
        errors.basic_plan_id  = 'Basic plan required'
    }

    if (required(values.eligible_member_count )) {
        errors.eligible_member_count  = 'Eligible member count required'
    }
    return errors;
   
};

















const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}


