import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
  ShowButton
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />

  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const UpgradePlanList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Basic Plan"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}

    >
      <Datagrid >
        <TextField source="id" />
        <TextField source="plan_name" label="Upgrade Level Name" />
        <TextField source="video_amount" label="Video Amount" />
        <TextField source="video_count" label="Video Count" />
        <TextField source="eligible_member_count" label="Eligible Member Count" />
        <FunctionField source="status" label="Status" render={record => {
          if (record['status'] === 'Deactive') {
            return (<span style={{ color: 'red' }}>Deactive</span>)
          } else {
            return (<span style={{ color: 'green' }}>Active</span>)
          }
        }} />
        <TextField source="public_video_count" label="Public Video Count" />
        <TextField source="public_video_amount" label="Public Video Amount" />
        <EditButton />
        <DeleteButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default UpgradePlanList;
