import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <VideoTypeSelectInput label="Type" source="urltype" alwaysOn fullWidth />
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const VideoListList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Video"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}
     
    >
      <Datagrid rowClick={"show"}>
        <TextField source="id" />
        
        <TextField source="video_type" label="Video Type" />       
        <TextField source="youtube_id" label="Youtube ID" />
        <TextField source="video_url" label="Video URL" />
        <TextField source="video_name" label="Video Name" />
        <FunctionField source="status" label="Status" render={record => {
                            if (record['status'] === 'Deactive') {
                                return (<span style={{ color: 'red' }}>Deactive</span>)
                            } else {
                                return (<span style={{ color: 'green' }}>Active</span>)
                            }
                        }} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default VideoListList;
