import * as React from "react";
import { Show, SimpleShowLayout, ChipField, Pagination, TabbedShowLayout, Datagrid, List, RichTextField, Tab, useRecordContext, Button, TextField, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
const PageTitle = () => {
  const record = useRecordContext();
  return <span>Merchandiser {record ? `${record.name}` : ''}</span>;
};
const PostPagination = ({ record, ...props }) => {
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 50, 100, { value: props.total, label: 'All' }]} {...props} />)
}
const MerchandiserApplyDetail = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Show title="Merchandiser Detail" {...props}>
       
 <SimpleShowLayout>
<TextField source="id" />
        <TextField source="profile_id"  label="Profile ID"/>
        <TextField source="name" label="Name" />  
        <TextField source="phone" label="Phone" />
        <TextField source="email" label="Email" />
        <TextField source="plan" label="Plan" />
        <TextField source="utr" label="UTR Number" /> 
        <FunctionField source="payment proof" label="Payment Proof" render={record => {  
              if (record['payment_proofurl']) {
                return ( <img style={{maxWidth:'100px'}} src={record['payment_proofurl']} alt={record.name}/>)
              }                       
            }} />  
              <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }                       
          }} /> 
          <ChipField source="user.basic_user_count" label="Downliner Count"/> 
          <ChipField source="user.premium_user_count" label="Downliner Count"/> 
          <TextField source="approvedby.name" label="Approved By" />
          <TextField source="declineby.name" label="Decline By" />
          <TextField source="reason" label="Reason" />
        <TextField source="created_at" label="Submited Date" />
        <TextField source="updated_at" label="Updated Date" />
        </SimpleShowLayout>
        
        </Show>
      
   
  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default MerchandiserApplyDetail