import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  useRedirect,
  useNotify,
  SaveButton,
} from "react-admin";

import Grid from "@material-ui/core/Grid";
import { ShopCategoryStatusSelectInput } from "../../UI/Input/SelectInput";
import { onShopKeywordEdit } from "../../Service/Validation/ShopKeywordValidation";
const PageTitle = ({ record }) => {
  return <span>Shop Keyword: {record ? record.name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const ShopKeywordEdit = (props) => {
 
  return (
    <Edit title={<PageTitle />} {...props} undoable={false} >
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onShopKeywordEdit} redirect="list">

      <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput label="Keyword" source="keyword" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput label="Icon" source="icon" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <ShopCategoryStatusSelectInput label="Status" source="status" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <TextInput label="Description" source="description" fullWidth />
          </Grid>
          </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default ShopKeywordEdit;
