import React,{Fragment} from "react";
import {
  List,
  Link,
  Datagrid,
  Filter,
  Pagination,
  useRecordContext,
  Button,
  TextInput,
  TextField,
  ChipField ,
  useListContext,
  FunctionField,
  ShowButton,
  EditButton,
  TopToolbar,

} from "react-admin";
import { makeStyles } from '@material-ui/core';
import {MerchandiserSelectInput,MerchandiserStatusSelectInput} from '../../UI/Input/SelectInput'
import BulkActiveButton from '../MerchandiserApply/Action/Bulk.Active.Button';
import BulkRejectButton from '../MerchandiserApply/Action/Bulk.Reject.Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import { stringify } from 'query-string';

const ListFilter = (props) => {
 
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <MerchandiserSelectInput label="Plan" source="plan" alwaysOn/>
    <MerchandiserStatusSelectInput label="Status" source="status" alwaysOn/>
   
  </Filter>)
};
const BulkActionButtons = ({ gift_id, ...props }) => {

  return (<Fragment>
      <BulkActiveButton label="Bulck Active"  {...props} />
      <BulkRejectButton label="Bulck Reject"  {...props} />
  </Fragment>)
}


const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}





const ListActions = (props) => {
  const record = useListContext();
 const filva = record.filterValues ? stringify(record.filterValues) : ''; 
 
 return (
 <TopToolbar>
    <Button  href={`//sboportal.org.in/admin/api/v1/merchandiseruser/export?${filva}`} label="Merchandiser Export" ><DownloadIcon /></Button>
    </TopToolbar >)
};




const PostPagination = ({ record, ...props }) => {    
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 200,500,]} {...props} />)
}

const MerchandiserApplyList = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Fragment>
    <List 
      {...props}
      pagination={<PostPagination />}
      title="User"
      bulkActionButtons={<BulkActionButtons />}
      filters={<ListFilter />}
      actions={<ListActions/>}
      sort={{ field: "name", order: "ASC" }}
      
      
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="profile_id"  label="Profile ID"/>
        <TextField source="name" label="Name" />  
        <TextField source="phone" label="Phone" />
        <TextField source="email" label="Email" />
        <TextField source="plan" label="Plan" />
        <TextField source="utr" label="UTR Number" /> 
        <FunctionField source="payment proof" label="Payment Proof" render={record => {  
              if (record['payment_proofurl']) {
                return ( <img style={{maxWidth:'100px'}} src={record['payment_proofurl']} alt={record.name}/>)
              }                       
            }} />  
              <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }                        
          }} /> 
          <ChipField source="user.basic_user_count" label="Basic User Count"/> 
          <ChipField source="user.premium_user_count" label="Premium User Count"/> 
          <TextField source="approvedby.name" label="Approved By" />
          <TextField source="declineby.name" label="Decline By" />
          <TextField source="reason" label="Reason" />
        <TextField source="created_at" label="Submited Date" />
           
        <EditButton/>
          <ShowButton/>
        </Datagrid>
    </List>
      
       </Fragment>
  );
};
const useStyles = makeStyles({
  numberCell: { width: 60 },
  actionCell: { width: 100 },
  layoutCell: { width: 120 }
})
export default MerchandiserApplyList;
