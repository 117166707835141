import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const UpgradePlanDetail = ({ ...props }) => (

    <Show title="Upgrade Plan Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="plan_name" label="Upgrade Level Name" />
            <TextField source="video_amount" label="Video Amount" />
            <TextField source="video_count" label="Video Count" />
            <TextField source="status" label="Status" />
            <TextField source="eligible_member_count" label="Eligible Member Count" />
            <TextField source="public_video_count" label="Public Video Count" />
            <TextField source="public_video_amount" label="Public Video Amount" />
        </SimpleShowLayout>
    </Show>
);
export default UpgradePlanDetail