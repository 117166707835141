import * as React from "react";
import { Show, SimpleShowLayout,useRecordContext,Button, TextField,FunctionField } from 'react-admin';
const PageTitle = () => {
    const record = useRecordContext();
    return <span>User {record ? `${record.name}` : ''}</span>;
  };
const PendingUserDetail = ({...props }) => (

    <Show title={<PageTitle />} {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="profile_id" label="Profile ID" />
        <TextField source="user_sponsorid" label="Sponsor Id" />
        <TextField source="user_sponsorname" label="Sponsor Name" /> 
        <TextField source="email" label="Email" /> 
        <TextField source="phone" label="Phone" /> 
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }else if(record['status'] === 'Suspended'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Suspended" size="small"></Button>)
          }                         
          }} /> 
           <TextField source="declineby.name" label="Decline By" />
         <TextField source="approvedby.name" label="Approved By" /> 
         <TextField source="approved_date" label="Approved Date" />
         <TextField source="utr" label="UTR Number" />  
         <FunctionField source="status" label="Status" render={record => {  
              if (record['payment_proofurl']) {
                return ( <span>Payment Proof <br/><br/><img src={record['payment_proofurl']} alt={record.name}/></span>)
              }                       
            }} /> 
        {/*TODO add another detail to user  */}
        </SimpleShowLayout>
    </Show>
     );
export default PendingUserDetail