import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import Grid from "@material-ui/core/Grid";
import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onCategoryListEdit } from "../../Service/Validation/CategoryListValidation";
const PageTitle = ({ record }) => {
  return <span>Category List: {record ? record.name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const CategoryListEdit = (props) => {
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onCategoryListEdit} redirect="list">

      <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput label="Category Name" source="name" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <TextInput label="Amount" source="amount" fullWidth />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
          <Status2SelectInput label="Status" source="status" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <TextInput label="Description" source="description" fullWidth />
          </Grid>
          </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default CategoryListEdit;
