import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
} from "react-admin";
const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
   
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const CodeList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Code"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}
     
    >
      <Datagrid rowClick={"show"}>
        <TextField source="id" />
        
        <TextField source="code" label="Code" />
        <FunctionField source="status" label="Status" render={record => {
                            if (record['status'] === 'Deactive') {
                                return (<span style={{ color: 'red' }}>Deactive</span>)
                            } else {
                                return (<span style={{ color: 'green' }}>Active</span>)
                            }
                        }} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default CodeList;
