
export const ShopUpload = (data) => {
    var formdata = new FormData()
    console.log(data);

    if (data.id) {
        formdata.append('id', data.id)
    }
    if (data.name) {
        formdata.append('name', data.name)
    }
    if (data.action) {
        formdata.append('action', data.action)
    }
    if (data.email) {
        formdata.append('email', data.email)
    }
    if (data.phone) {
        formdata.append('phone', data.phone)
    }
    if (data.password) {
        formdata.append('password', data.password)
    }
    if(data.confirmpassword){
        formdata.append('confirmpassword',data.confirmpassword)
    } 
    if(data.shop_profile_photo){
        formdata.append('shop_profile_photo',data.shop_profile_photo.rawFile)
    }
    if (data.photos) {
        data.photos.forEach((val, inx) => {
            formdata.append('photos[' + inx + ']', val.rawFile)
        })
    }
    if(data.status){
        formdata.append('status',data.status)
    }
    if(data.gst_number){
        formdata.append('gst_number',data.gst_number)
    }
    if(data.address){
        formdata.append('address',data.address)
    }
    if(data.city){
        formdata.append('city',data.city)
    }
    if(data.state){
        formdata.append('state',data.state)
    }
    if(data.country){
        formdata.append('country',data.country)
    }
    if(data.owner_name){
        formdata.append('owner_name',data.owner_name)
    }
    if(data.owner_email){
        formdata.append('owner_email',data.owner_email)
    }
    if(data.owner_phone){
        formdata.append('owner_phone',data.owner_phone)
    }
    if(data.owner_address){
        formdata.append('owner_address',data.owner_address)
    }
    if(data.customer_discount){
        formdata.append('customer_discount',data.customer_discount)
    }
    if(data.sbo_margin){
        formdata.append('sbo_margin',data.sbo_margin)
    }
    if(data.latitude){
        formdata.append('latitude',data.latitude)
    }
    if(data.longitude){
        formdata.append('longitude',data.longitude)
    }
    if(data.description){
        formdata.append('description',data.description)
    }
    if (data.working_hours) {
        data.working_hours.forEach((val, inx) => {
            formdata.append('working_hours[' + inx + '][day]', val.day)
            formdata.append('working_hours[' + inx + '][start_time]', val.start_time)
            formdata.append('working_hours[' + inx + '][end_time]', val.end_time)
        })
    }
    if (data.category_id) {
        formdata.append('category',data.category_id)
    }
   
    console.log(formdata);
    return formdata;
}