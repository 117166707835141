import React from "react";
import {Edit,useRecordContext,required,FormDataConsumer,FunctionField,SimpleForm,TextInput, Toolbar, ListButton,SaveButton} from "react-admin";

import { DigitalTaskStatusSelectInput,DigitalTaskSelectInput } from "../../UI/Input/SelectInput";

import Grid from "@material-ui/core/Grid";


const PageTitle = () => {
  const record = useRecordContext();
  return <span>Digital Task Application for {record ? `${record.profile_id}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const DigitalTaskApplicationEdit = (props) => {
  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />}  redirect="list" defaultValues={{ action: "full" }}>       
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Profile ID" source="profile_id" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="User Name" source="user_name" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
              <TextInput disabled label="Type" source="type" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Category" source="category" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
            <TextInput disabled label="No Of Post" source="noofpost" fullWidth />
            </Grid>     
                
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Source Created Date" source="source_created_date" fullWidth />
            </Grid>
           
            <Grid item sm={12}  lg={4}>
            <FunctionField source="status" label="Status" render={record => {  
               if(record['status'] === 'Pending') {
                return (<DigitalTaskStatusSelectInput validate={required()} label="Status" fullWidth source="status"/>)
               }else{
                return (<DigitalTaskStatusSelectInput disabled fullWidth label="Current_status" source="status"/>)
               }
            }} /> 
            </Grid>     
            <Grid item sm={12}  lg={4}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.status === "Rejected" && (
                  <TextInput source="reason"  label="Reason" fullWidth validate={required()}/>
                )
              }
            </FormDataConsumer>
            
            </Grid>       
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={12}>
                <TextInput disabled label="URL" source="url" fullWidth />
            </Grid>
           
           
          </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default DigitalTaskApplicationEdit;
