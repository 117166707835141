import React from "react";
import {
  Create,
  SimpleForm,
  FileField,
  Toolbar,
  FileInput,
  ListButton,
  SaveButton,
} from "react-admin";
import { onBonusBulckCreate } from "../../Service/Validation/AddBonusValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Add Bonus</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const AdminBulckUserAddWorkingAmountCreate = (props) => {
 
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onBonusBulckCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
        <FileInput source="attachments">
    <FileField source="src" title="title" />
</FileInput>

       
        </SanitizedGrid>
       
      </SimpleForm>
    </Create>
  );
};
export default AdminBulckUserAddWorkingAmountCreate;
