import { fetchPost } from '../Service/ApiService'

export const bulkSendDigitalTask = (selectedIds, resolve, reject) => {
   

    try {
        fetchPost(`digitaltaskapplication/bulksend`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};


export const bulkRejectDigitalTask = (selectedIds, resolve, reject) => {   

    try {
        fetchPost(`digitaltaskapplication/bulkreject`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};







