import React,{Fragment} from "react";
import {
  List,
  Datagrid,
  Filter,
  EditButton,
  Button,
  TextInput,
  TextField,
  FunctionField,
  ShowButton,
  useRecordContext,
  Link,
  Pagination,
  TopToolbar,
  ChipField,
  UrlField 
} from "react-admin";
import BulkActiveButton from '../DigitalTaskApplication/Action/Bulk.Active.Button';

import BulkRejectButton from '../DigitalTaskApplication/Action/Bulk.Reject.Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import {DigitalTaskStatusSelectInput,DigitalTaskSelectInput} from '../../UI/Input/SelectInput'

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100,200,300,400,500,1000,2000]} />;

const BulkActionButtons = ({  ...props }) => {

  return (<Fragment>
      <BulkActiveButton label="Bulck Active"  {...props} />
      <BulkRejectButton label="Bulck Reject"  {...props} />

  </Fragment>)
}
const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}



const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
    <DigitalTaskStatusSelectInput label="Status" source="status" alwaysOn/>
    <DigitalTaskSelectInput label="Type" source="type" alwaysOn/>
  </Filter>
);
////www.stage.tiruvannamalaiproperties.in/api/v1/1/customers/export?
const ListActions = () => (
  <TopToolbar>
    
  </TopToolbar>
);

const DigitalTaskApplicationList = ({ ...props }) => {
 
  return (
    <Fragment>
    <List
      {...props}
     
      title="Digital Task Application"
      bulkActionButtons={<BulkActionButtons />}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
      actions={<ListActions/>}
      pagination={<PostPagination/>}
    >
      <Datagrid >
      <TextField source="id" />
        <TextField source="profile_id" label="Profile Id" />
        <TextField source="user_name" label="User Name" />
        <TextField source="name" label="Name" />  
        <UrlField  source="url" label="URL" />      
        <TextField source="category" label="Category" />      
        <TextField source="noofpost" label="NO of Post" />     
        <TextField source="type" label="Type" />   
        <TextField label="Source Created Date" source="source_created_date"/> 
        <TextField label="Approved by" source="approvedby.name"/> 
        <TextField label="Decline by" source="rejectedby.name"/>
        <TextField label="Reason" source="reason"/>
        <TextField label="Device" source="device"/>
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Rejected') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Rejected" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }else if(record['status'] === 'Cancel'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }                         
          }} /> 
           <FunctionField source="status" label="Edit Action" render={record => {  
            if(record['status'] === 'Pending') {
              return (  <EditButton/>  )            
            }      
                             
          }} /> 
                 <ShowButton/> 
      </Datagrid>
    </List>
      
       </Fragment>
  );
};

export default DigitalTaskApplicationList;
