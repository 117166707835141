import React from "react";
import { Edit, TabbedForm,useEditController, ReferenceInput, AutocompleteInput, SelectInput, FormTab, FormDataConsumer, NumberInput, useRecordContext, FunctionField, required, SimpleForm, ImageInput, ImageField, TextInput, Toolbar, ListButton, SaveButton } from "react-admin";

import { StatusSelectInput } from "../../UI/Input/SelectInput";
import { onUserEdit } from "../../Service/Validation/UserValidation";
import Grid from "@material-ui/core/Grid";
import { UserPendingStatusSelectInput, UserBasicPlanSelectInput, UserBlockedStatusSelectInput,UserActiveStatusSelectInput, UserSuspendedStatusSelectInput, UserDeclineStatusSelectInput } from '../../UI/Input/SelectInput'
// const PageTitle = ({ record }) => {
//   return <span>User: {record ? record.name : ""}</span>;
// };
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const UserEdit = (props) => {
  const filterToQuery = searchText => ({ name_ilike: `%${searchText}%` });
  const controllerProps  = useEditController(props);
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>

      <TabbedForm toolbar={<FormToolbar />} validate={onUserEdit} redirect="list" defaultValues={{ action: "full" }}>
        <FormTab label="Profile">
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Sponsor ID" source="user_sponsorid" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Sponsor Name" source="user_sponsorname" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <UserBasicPlanSelectInput label="Current Plan" source="current_plan" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={3}>
              <TextInput label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12} lg={3}>
              <TextInput label="Email" source="email" fullWidth />
            </Grid>
            <Grid item sm={12} lg={3}>
              <TextInput label="Phone" source="phone" fullWidth />
            </Grid>
            <Grid item sm={12} lg={3}>
              <TextInput source="rating_point" label="Rating" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Pending') {
                  return (<UserPendingStatusSelectInput label="Status" source="status" fullWidth />)
                } else if (record['status'] === 'Active') {
                  return (<UserActiveStatusSelectInput label="Status" source="status" fullWidth />)
                } else if (record['status'] === 'Decline') {
                  return (<UserDeclineStatusSelectInput label="Status" source="status" fullWidth />)
                } else if (record['status'] === 'Suspended') {
                  return (<UserSuspendedStatusSelectInput label="Status" source="status" fullWidth />)
                } else if (record['status'] === 'Blocked') {
                  return (<UserBlockedStatusSelectInput label="Status" source="status" fullWidth />)
                }
              }} />
            </Grid>
            <Grid item sm={12} lg={4}>
              <NumberInput source="basic_user_count" label="Basic User Count" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <NumberInput source="premium_user_count" label="Premium User Count" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput source="utr" label="UTR Number" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <FunctionField source="status" label="Status" render={record => {
                if (record['payment_proofurl']) {
                  return (<span>Payment Proof <br /><br /><img style={{ maxWidth: '250px' }} src={record['payment_proofurl']} alt={record.name} /></span>)
                }
              }} />
            </Grid>

            <Grid item sm={12} lg={4}>
              <FunctionField source="join_type" render={record => {
                if (record['join_type'] == 'Referral') {
                  return (<ImageInput source="payment_proof" label="Payment Proof" accept="image/*" maxSize={2000000}>
                    <ImageField source="src" title="title" />
                  </ImageInput>)
                }
              }} />
            </Grid>
            <Grid item sm={12} lg={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  (formData.status === "Decline" || formData.status === "Suspended") && (
                    <TextInput source="reason" label="Reason" fullWidth validate={required()} />
                  )
                }
              </FormDataConsumer>

            </Grid>

          </SanitizedGrid>
        </FormTab>
        {controllerProps.record && controllerProps.record.userinfo?
        <FormTab label="User Profile" path="userprofile">
          <ReferenceInput label="Countries" source="country" perPage={30} {...props} reference="getcountry">
            <SelectInput optionText="name" optionValue="name_id" fullWidth />

          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData && formData.country) {
                return (
                  <ReferenceInput label="States" filter={{ country_id: formData.country }} source="states" perPage={30} {...props} reference="getstates">

                    <SelectInput optionText="name" optionValue="name_id" fullWidth />
                  </ReferenceInput>
                )
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData && formData.states) {
                return (
                  <ReferenceInput label="Cities" filter={{ state_id: formData.states }} fullWidth source="cities" perPage={30} {...props} reference="getcities">
                    <SelectInput optionText="name" optionValue="name" fullWidth />
                  </ReferenceInput>
                )
              }
            }}
          </FormDataConsumer>
        </FormTab>:''}

        
        <FormTab label="User Password" path="userpassword">
        <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={6}>
        <TextInput label="Password" source="pword"  fullWidth />
        </Grid>
        <Grid item sm={12} lg={6}>
        <TextInput label="Confirm Password" source="cpassword" fullWidth />
        </Grid>
        </SanitizedGrid>
        </FormTab>

      </TabbedForm>


    </Edit>
  );
};

export default UserEdit;
