import React from "react";
import {
  Edit,
  SimpleForm,
  ImageInput,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
  ImageField
} from "react-admin";

import { Status2SelectInput } from "../../UI/Input/SelectInput";
import { onArticleEdit } from "../../Service/Validation/ArticleValidation";
import Grid from "@material-ui/core/Grid";
import { RichTextInput } from 'ra-input-rich-text';

const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const PageTitle = ({ record }) => {
  return <span>Basic Plan: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const ArticleEdit = (props) => {  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
            <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar/>} defaultValues={{ action: "full" }} validate={onArticleEdit} redirect="list">                
            <SanitizedGrid container spacing={3} fullWidth>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Page Title" source="page_title" fullWidth/>
        </Grid>

        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Before Description Video" source="bvideo" fullWidth/>
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
        <ImageInput source="image" label="Image">
          <ImageField source="src" title="title" />
    </ImageInput>
        </Grid>

        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="Website Link" source="websitelink" fullWidth/>
        </Grid>
       
        <Grid item sm={12} md={12} lg={12}>
        <RichTextInput  label="Description" source="description" fullWidth/>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
        <TextInput label="After Description Video" source="avideo" fullWidth/>
        </Grid>

        <Grid item sm={12} md={6} lg={6}>
<Status2SelectInput label="Status" source="status" fullWidth/>
</Grid>
          
        </SanitizedGrid>
            </SimpleForm>
        </Edit>
  );
};

export default ArticleEdit;
