const userProfile = (values) => {
        
    const errors = {}
    
    if (required(values.name)) {
        errors.name = 'Name is required';
    }
    else if(minLength(3,values.name)){
        errors.name = 'The name must be at least 3 characters.';
    }else if(maxLength(120,values.name)){
        errors.name = 'The name may not be greater than 120 characters.';
    }

    if (required(values.email)) {
        errors.email = 'Email is required';
    }else if (email(values.email)) {
        errors.email = 'Valid email id required';
    }

    if (required(values.phone)) {
        errors.phone = 'Phone is required';
    }else if(maxLength(10,values.phone)){
        errors.phone = 'Phone number max 10 digit';
    }else if(mobile(values.phone)){
        errors.phone = 'Phone number numeric only';
    }
    if (required(values.status)) {
        errors.status = 'Status is required';
    }
    return errors
}

export const onUserEdit = (values) => {
console.log(values);
    var errors = userProfile(values)

    if (required(values.current_plan)) {
        errors.current_plan = 'Plan is required';
    }
    if(values.join_type=="Referral"){
        if (required(values.payment_proof)) {
            errors.payment_proof = 'Payment proof is required';
        }
    }

    if(values.pword != ""){
   
    if(values.cpassword !== values.pword){
        errors.cpassword = 'Password and confirm password do not match.'
    }
    }
    
    return errors
};

export const onUserCommandsEdit = (values) => {
    console.log('values');
     
    const errors = {}
        if (required(values.rating)) {
            errors.rating = 'Rating is required';
        }

   
            if (required(values.comments)) {
                errors.comments = 'Comments is required';
            }
       
        
        return errors
    };







const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_MOBILE = /^([0-9\s\-\+\(\)]*)$/
const REGEX_LETTER = /^[A-Za-z]+$/

const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}

export const required = (value) =>{
    return isEmpty(value)
}

export const minLength = (length, value) =>{
    return (!isEmpty(value) && value.length < length)
}

export const maxLength = (length, value) =>{
    return (!isEmpty(value) && value.length > length)
}

export const email = (value) =>{
    return regex(REGEX_EMAIL, value)
}

export const mobile = (value) =>{
    return regex(REGEX_MOBILE, value)
}
 export const letterOnly=(value)=>{
    return regex(REGEX_LETTER,value);
 }
export const regex = (pattern, value) =>{
    return (!isEmpty(value) && typeof value === 'string' && !pattern.test(value))
}