import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  FileInput, 
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";
import { VideoTypeSelectInput,Status2SelectInput } from "../../UI/Input/SelectInput";
import { onVideoListCreate } from "../../Service/Validation/VideoListValidation";
import Grid from "@material-ui/core/Grid";

const PageTitle = () => {
  return <span>Create Video List</span>;
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const VideoListCreate = (props) => {
  console.log(props);
  return (
    <Create title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm toolbar={<FormToolbar />} reValidateMode="onBlur" warnWhenUnsavedChanges validate={onVideoListCreate}>
        <SanitizedGrid container spacing={3} fullWidth>
         
          <Grid item sm={12} md={6} lg={6}>
            <VideoTypeSelectInput label="Type" source="video_type" fullWidth />
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.video_type === "Video" && (
                  <FileInput fullWidth source="video_url" maxSize={5000000} accept="video/*" placeholder={<p>Drop a file to upload, or click to select it. and upload only 5 mb</p>}>
                    <FileField source="src" title="title" />
                  </FileInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.video_type === "Youtube" && (
                  <TextInput label="Youtube" source="youtube_id" fullWidth />
                )
              }
            </FormDataConsumer>
          </Grid>
          
        </SanitizedGrid>
        <SanitizedGrid container spacing={3} fullWidth>
       
          <Grid item sm={12} md={6} lg={6}>
          <TextInput label="Video Name" source="video_name" fullWidth/>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                <Status2SelectInput label="Status" source="status" fullWidth/>
                </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};
export default VideoListCreate;
