import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
} from "react-admin";

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const ShopKeywordList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Keyword"     
      filters={<ListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick={"show"}  bulkActionButtons={<ListActionButtons />}>       
        <TextField source="keyword" label="Keyword" />
        <FunctionField source="status" label="Status" render={record => {
          if (record['status'] === 'Inactive') {
            return (<span style={{ color: 'red' }}>Deactive</span>)
          } else {
            return (<span style={{ color: 'green' }}>Active</span>)
          }
        }} />
        <TextField source="description" label="Description" />
        <TextField source="icon" label="Icon" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default ShopKeywordList;
