import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  useNotify,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { StatusSelectInput } from "../../UI/Input/SelectInput";
import { onAdminUserEdit } from "../../Service/Validation/AdminUserValidation";
import Grid from "@material-ui/core/Grid";
const PageTitle = ({ record }) => {
  return <span>Admin User: {record ? record.name : ""}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const AdminUserEdit = (props) => {
  const notify = useNotify();

  const onError = (error) => {
    var errorArr = [];
    Object.keys(error.body.errors).forEach(function (key) {
      errorArr.push(error.body.errors[key]);
    });
    notify(`Could not create item: ${errorArr.toString()}`, { type: "error" });
  };
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <TabbedForm warnWhenUnsavedChanges
        toolbar={<FormToolbar />}
        defaultValues={{ action: "full" }}
        validate={onAdminUserEdit}
      >
        <FormTab label="Profile">
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Email" source="email" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} md={6} lg={6}>
              <TextInput label="Phone" source="phone" fullWidth />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <StatusSelectInput label="Status" source="status" fullWidth />
            </Grid>
          </SanitizedGrid>
        </FormTab>
        <FormTab label="Password">
          <TextInput label="Password" source="password" type="password" />
          <TextInput
            label="Confirm Password"
            source="confirmpassword"
            type="password"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AdminUserEdit;
