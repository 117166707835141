import React from "react";
import {Edit,FormDataConsumer,NumberInput,useRecordContext,FunctionField,required,SimpleForm,ImageInput,ImageField,TextInput, Toolbar, ListButton,SaveButton} from "react-admin";

import { StatusSelectInput } from "../../UI/Input/SelectInput";
import { onUserEdit } from "../../Service/Validation/UserValidation";
import Grid from "@material-ui/core/Grid";
import {UserPendingStatusSelectInput,UserBasicPlanSelectInput,UserActiveStatusSelectInput,UserSuspendedStatusSelectInput,UserDeclineStatusSelectInput} from '../../UI/Input/SelectInput'
// const PageTitle = ({ record }) => {
//   return <span>User: {record ? record.name : ""}</span>;
// };
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const MerchandiserApplyEdit = (props) => {
  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm  toolbar={<FormToolbar />}  redirect="list" defaultValues={{ action: "full" }}>       
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="profile ID" source="profile_id" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
              <TextInput disabled label="Plan" source="plan" fullWidth/>
            </Grid>             
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
         
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Email" source="email" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
                <TextInput disabled label="Phone" source="phone" fullWidth />
            </Grid>      

            <Grid item sm={12}  lg={4}>
            <TextInput disabled source="utr" label="UTR Number" fullWidth/>
                                  
            </Grid>
          
          </SanitizedGrid>
          
          <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12}  lg={4}>
          <FunctionField source="status" label="Status" render={record => {  
              if (record['status'] === 'Pending') {
                return ( <UserPendingStatusSelectInput label="Status" source="status" fullWidth />)
              }else if(record['status'] === 'Active'){
                return ( <UserActiveStatusSelectInput label="Status" source="status" fullWidth />) 
              } else if(record['status'] === 'Decline'){
                return ( <UserDeclineStatusSelectInput label="Status" source="status" fullWidth />) 
              }                         
            }} />    
            </Grid>
         
            <Grid item sm={12}  lg={4}>
              <NumberInput disabled source="user.premium_user_count" label="Premium User Count"  fullWidth/>
            </Grid>
            <Grid item sm={12}  lg={4}>
              <NumberInput  disabled source="user.basic_user_count" label="Basic User Count"  fullWidth/> 
            </Grid>
            <Grid item sm={12}  lg={6}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                (formData.status === "Decline" || formData.status === "Suspended") && (
                  <TextInput source="reason" label="Reason" fullWidth validate={required()}/>
                )
              }
            </FormDataConsumer>
            
            </Grid>
           
            </SanitizedGrid>
         

      </SimpleForm>
    </Edit>
  );
};

export default MerchandiserApplyEdit;
