import React from 'react'


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

export const DateRangeInput = (props) => {
    const handleApply = (event, picker) => {
        props.onChange(picker.startDate.format('YYYY-MM-DD') + ',' + picker.endDate.format('YYYY-MM-DD'))
        picker.element.val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
    };
    const handleCancel = (event, picker) => {
        picker.element.val('Date Range');
        props.onClear();
    };
    return (
        <div>
            <DateRangePicker initialSettings={{ autoUpdateInput: false }} onApply={handleApply} onCancel={handleCancel} >
                <input readOnly={true} style={{ backgroundColor: '#e3e3e3', color: '#676767', fontSize: '16px', padding: '20px 12px 10px', background: 'gainsboro', border: '0', display: 'block', margin: '0px 0px 9px 0px', borderBottom: '1px solid' }} className="form-control" defaultValue="Date Range" />
            </DateRangePicker>
        </div>
    )
}
