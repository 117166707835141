import { fetchPost } from '../Service/ApiService'

export const bulkSendPlan = (selectedIds, resolve, reject) => {
   

    try {
        fetchPost(`planupgraderequest/bulksend`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const bulkRejectPlan = (selectedIds, resolve, reject) => {
   

    try {
        fetchPost(`planupgraderequest/bulkreject`, selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};






