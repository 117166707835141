import React from "react";
import {Edit,FormDataConsumer,useRecordContext,FunctionField,required,SimpleForm,ImageInput,ImageField,TextInput, Toolbar, ListButton,SaveButton} from "react-admin";

import { StatusSelectInput } from "../../UI/Input/SelectInput";
import { onUserEdit } from "../../Service/Validation/UserValidation";
import Grid from "@material-ui/core/Grid";
import {UserPendingStatusSelectInput,BasicPlanSelectInput,UserActiveStatusSelectInput,UserSuspendedStatusSelectInput,UserDeclineStatusSelectInput} from '../../UI/Input/SelectInput'
// const PageTitle = ({ record }) => {
//   return <span>User: {record ? record.name : ""}</span>;
// };
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const PendingUserEdit = (props) => {
  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} validate={onUserEdit} redirect="list" defaultValues={{ action: "full" }}>       
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Sponsor ID" source="user_sponsorid" fullWidth />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextInput disabled label="Sponsor Name" source="user_sponsorname" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
              <TextInput label="Name" source="name" fullWidth />
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={4}>
                <TextInput label="Email" source="email" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
                <TextInput label="Phone" source="phone" fullWidth />
            </Grid>
            <Grid item sm={12}  lg={4}>
            <FunctionField source="status" label="Status" render={record => {  
              if (record['status'] === 'Pending') {
                return ( <UserPendingStatusSelectInput label="Status" source="status" fullWidth />)
              }else if(record['status'] === 'Active'){
                return ( <UserActiveStatusSelectInput label="Status" source="status" fullWidth />) 
              } else if(record['status'] === 'Decline'){
                return ( <UserDeclineStatusSelectInput label="Status" source="status" fullWidth />) 
              }else if(record['status'] === 'Suspended'){
                return ( <UserSuspendedStatusSelectInput label="Status" source="status" fullWidth />) 
              }                         
            }} /> 
              
            </Grid>
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
            <Grid item sm={12}  lg={6}>
            <ImageInput source="payment_proof" label="Payment Proof" accept="image/*" maxSize={2000000}>
              <ImageField source="src" title="title" />
            </ImageInput>
            </Grid>
            <Grid item sm={12}  lg={6}>
              <BasicPlanSelectInput label="Current Plan" source="current_plan" fullWidth/>
            </Grid>  
          </SanitizedGrid>
          <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12}  lg={6}>
          <FunctionField source="status" label="Status" render={record => {  
              if (record['payment_proofurl']) {
                return ( <span>Payment Proof <br/><br/><img src={record['payment_proofurl']} alt={record.name}/></span>)
              }                       
            }} /> 
            </Grid> 
 <Grid item sm={12}  lg={6}>
<TextInput source="utr" label="UTR Number" />
          </Grid> 
            <Grid item sm={12}  lg={6}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                (formData.status === "Decline" || formData.status === "Suspended") && (
                  <TextInput source="reason" label="Reason" fullWidth validate={required()}/>
                )
              }
            </FormDataConsumer>
            
            </Grid>
            </SanitizedGrid>
           

      </SimpleForm>
    </Edit>
  );
};

export default PendingUserEdit;
