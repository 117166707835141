export const onLanguageVideoCreate = (values) => {

    const errors = {}
     if (required(values.name)) {
        errors.name = 'Name is required'
    } 
    if (required(values.description)) {
        errors.description = 'Description required'
    }
    if (required(values.urltype)) {
        errors.urltype = 'Type required'
    }
    

    return errors;
   
};

export const onLanguageVideoEdit = (values) => {

    const errors = {}
    if (required(values.name)) { 
        errors.name = ['Name is required']
    } 
    if (required(values.description)) {
        errors.description = ['Description required']
    }
    if (required(values.urltype)) {
        errors.urltype = ['Type required']
    }   

    return errors;
   
};
const isEmpty = (value: any) =>{
    return (typeof value === 'undefined' || value === null || value === '')
}
export const required = (value) =>{
    return isEmpty(value)
}