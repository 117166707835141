import { fetchPost,fetchGet, fetchPut } from '../Service/ApiService'

export const deleteShopImage = (shopid, imagekey, resolve, reject) => {

    try {
        fetchPost(`/shop/`+shopid+'/photos/delete', {"key":imagekey}, {
            success: (res) => { resolve(res)},
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to delete image" })
    }
};

export const saveShopImagesOrder = (data,layoutid,resolve, reject) => {  
    var datas={
        'items':JSON.stringify(data),
        'layoutid':layoutid,
    }
    try {
        fetchPut(`/layout/`+layoutid+'/saveappimageorder', datas, {
            success: (res) => { resolve(res)},
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to save data" })
    }
};

export const assignCategory = (selectedIds,shopid, resolve, reject) => {

    try {
        fetchPost('shop/'+shopid+'/assigncategory', selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const removeAssignCategory = (selectedIds,shopid, resolve, reject) => {

    try {
        fetchPost('shop/'+shopid+'/removeassigncategory', selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const assignKeyword = (selectedIds,shopid, resolve, reject) => {

    try {
        fetchPost('shop/'+shopid+'/assignkeyword', selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};

export const removeAssignKeyword = (selectedIds,shopid, resolve, reject) => {

    try {
        fetchPost('shop/'+shopid+'/removeassignkeyword', selectedIds, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};