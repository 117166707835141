import React from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  FileInput,
  TextInput,
  FileField,
  Toolbar,
  ListButton,
  SaveButton,
} from "react-admin";

import { VideoTypeSelectInput,Status2SelectInput } from "../../UI/Input/SelectInput";
import { onVideoListEdit } from "../../Service/Validation/VideoListValidation";
const PageTitle = ({ record }) => {
  return <span>Video List: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const VideoListEdit = (props) => {  
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
            <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar/>} defaultValues={{ action: "full" }} validate={onVideoListEdit} redirect="list">                
               
                <VideoTypeSelectInput label="Type" source="video_type" fullWidth />
                <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.video_type === "Video" && (
                  <FileInput source="video_url"  maxSize={5000000} accept="video/*" placeholder={<p>Drop a file to upload, or click to select it. and upload only 5 mb</p>}>
                    <FileField source="src" title="title" />
                  </FileInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.video_type === "Youtube" && (
                  <TextInput label="Youtube" source="youtube_id" fullWidth />
                )
              }
            </FormDataConsumer>
            <TextInput label="Video Name" source="video_name" fullWidth/>
           
              <Status2SelectInput label="Status" source="status" fullWidth />
            
            </SimpleForm>
        </Edit>
  );
};

export default VideoListEdit;
