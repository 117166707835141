import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const ShopBankDetail = ({...props }) => (

    <Show title="Admin User Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="account_no" label="Account Number" />
            <TextField source="ifsc" label="IFSC" />
            <TextField source="bank_name" label="Bank Name" />
            <TextField source="account_holder_name" label="Account Holder Name" />
            <TextField source="branch" label="Branch Name" />            
        </SimpleShowLayout>
    </Show>
     );
export default ShopBankDetail