import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField, useRecordContext, Link } from 'react-admin';

const ContentWriterKeywordAmountDetail = ({ ...props }) => (

    <Show title="Content writer keyword amount detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="keyword_count" label="Keyword Count" />
            <TextField source="amount" label="Amount" />
        </SimpleShowLayout>
    </Show>
);
export default ContentWriterKeywordAmountDetail