import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { StatusSelectInput } from '../../UI/Input/SelectInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const ContentWriterKeywordAmountList = ({ ...props }) => {

    return (
        <List {...props} title="Content Writer Keywords" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'keyword', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <TextField source="keyword_count" label="Keyword Count" />
                <TextField source="amount" label="Amount" />
                <EditButton />
                <ShowButton />
                <DeleteButton />
            </Datagrid>

        </List>
    )
}


export default ContentWriterKeywordAmountList